import React, { useState, useEffect } from 'react';
import Sidebar from "../../../Sidebar/SideBar"
import { useParams } from 'react-router-dom';
import { StudyStyled } from "./style"
import Logo from "../../../../images/login-logo.png"
import { useNavigate } from "react-router-dom";
import { API } from "../../../../api/api";
import { toast } from "react-toastify";

function AddSiteSubmisson() {
    const navigate = useNavigate();
    const [studyName, setStudyName] = useState("");
    const [siteName, setSiteName] = useState("");
    const [address, setAddress] = useState("");
    const [piEmail, setPiEmail] = useState("");
    const [piName, setPiName] = useState("");
    const [specialty, setSpecialty] = useState("");
    const [crcName, setCrcName] = useState("");
    const [crcEmail, setCrcEmail] = useState("");
    const [additionalComment, setAdditionalComment] = useState("");
    const [studyManagerComment, setStudyManagerComment] = useState("");
    const [crcPhone, setCrcPhone] = useState("");
    const [ssvDate, setSsvDate] = useState("");
    const [cv, setCv] = useState("");
    const [snpa, setSnpa] = useState("");
    const [file, setFile] = useState(null);
    const [projectManagerEmail, setProjectManagerEmail] = useState("");
    const [projectManagerName, setProjectManagerName] = useState("");
    const [submission, setSubmisson] = useState({});
    const [isReadyForBudgetOrContract, setIsReadyForBudgetOrContract] = useState("");
    const [active, setActive] = useState("");
    const [bd, setBd] = useState("");
    let { id } = useParams();

    const handleSiteNameChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, siteName: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };

    const handlePiEmailChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, piEmail: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };
    const handlePiNameChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, piName: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };



    const handleCrcNameChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, crcName: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };

    const handleCrcEmailChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, crcEmail: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };


    const handleProjectManagerNameChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, projectManagerName: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };
    const handleProjectManagerEmailChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, projectManagerEmail: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };
    const handleSsvDateChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, ssvDate: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };




    // const handleIsReadyForPISubmissionChange = (e) => {
    //     const isChecked = e.target.checked;
    //     const updateSubmission = { ...submission, isReadyForPISubmission: isChecked };
    //     console.log(submission.isReadyForPISubmission);
    //     setSubmisson(updateSubmission);
    // };
    const handleIsReadyForBudgetOrContractChange = (e) => {
        const isChecked = e.target.checked;
        const updateSiteSubmissionVisit = { ...submission, isReadyForBudgetOrContract: isChecked };
        setSubmisson(updateSiteSubmissionVisit);
    };
    useEffect(() => {
        getSubmisson(id);
    }, []);
    const getSubmisson = async (id) => {

        await API.getSubmisson(id,
        )
            .then((resp) => {
                console.log();
                if (resp.status == 200) {
                    setSubmisson(resp.data.data);
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };




    const updateSiteSubmissionVisit = async () => {
        submission.id = id
        await API.updateSiteSubmissionVisit(submission)

            .then((resp) => {
                // console.log();
                if (resp.status == 200) {
                    // console.log(resp.data.data);
                    // setLead(resp.data.data);
                    getSubmisson(id);
                    navigate("/ssv");
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };

    return (

        <>
            <Sidebar>
                <StudyStyled>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div></div>
                                    <div className="Bell-Main-Section">
                                    </div>
                                </div>

                                <div className="Call-Center-Title">
                                    <span className="call-center">Update Site Selection Visit</span>

                                </div>
                            </div>
                            <div className="Main-Detail-Box">

                                <div className="Names-Form">

                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Site Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Site Name"
                                                value={
                                                    submission.siteName ? submission.siteName : siteName
                                                }
                                                onChange={handleSiteNameChange}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="Names-Form">

                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            PI Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter PI Name"

                                                value={
                                                    submission.piName ? submission.piName : piName
                                                }
                                                onChange={handlePiNameChange}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            PI Email</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter PI Email"

                                                value={
                                                    submission.piEmail ? submission.piEmail : piEmail
                                                }
                                                onChange={handlePiEmailChange}

                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            CRC Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Crc Name"
                                                value={
                                                    submission.crcName ? submission.crcName : crcName
                                                }
                                                onChange={handleCrcNameChange}

                                            />
                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            CRC Email</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Email"
                                                placeholder="Enter Crc Email"


                                                value={
                                                    submission.crcEmail ? submission.crcEmail : crcEmail
                                                }
                                                onChange={handleCrcEmailChange}

                                            />



                                        </div>
                                    </div>

                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Project Manager Name</span>
                                        <div className="FormBox">
                                            <input

                                                className="NameBox"
                                                type="name"
                                                placeholder="Enter Project Manager Name"

                                                value={
                                                    submission.projectManagerName ? submission.projectManagerName : projectManagerName
                                                }
                                                onChange={handleProjectManagerNameChange}

                                            />



                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Project Manager Email</span>
                                        <div className="FormBox">
                                            <input

                                                className="NameBox"
                                                type="email"
                                                placeholder="Enter Project Manager Email"

                                                value={
                                                    submission.projectManagerEmail ? submission.projectManagerEmail : projectManagerEmail
                                                }
                                                onChange={handleProjectManagerEmailChange}

                                            />



                                        </div>
                                    </div>


                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            SSV Date</span>
                                        <div className="FormBox">
                                            <input

                                                className="NameBox"
                                                type="date"
                                                placeholder="Enter Date"

                                                value={
                                                    submission.ssvDate ? submission.ssvDate : ssvDate
                                                }
                                                onChange={handleSsvDateChange}

                                            />



                                        </div>
                                    </div>



                                </div>



                                <div className="Names-Form-checkbox">
                                    <div className='Check-Box-Containers'>
                                        <div className='Check-Box-Section'>
                                            <input
                                                className="Checkbox"
                                                type="checkbox"
                                                checked={submission.isReadyForBudgetOrContract}
                                                onChange={handleIsReadyForBudgetOrContractChange}
                                            />
                                            <span className='Title'>Ready for Contract/Budget</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="pagination">
                                    <button className='Button-1' onClick={() => navigate("/ssv")}>Cancel</button>
                                    <button className="Button-2" onClick={(e) => updateSiteSubmissionVisit(e)}>Submit</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </StudyStyled >
            </Sidebar>
        </>
    )
}


export default AddSiteSubmisson