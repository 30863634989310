import styled from "styled-components";

export const StudyStyled = styled.div`
display: flex;
width: 100%;
background-color: #F3F3F9;
.Business-Main-Section {
    width: 100%;
    display: flex;
    flex-direction: column;

    height: 100vh;
    overflow: auto;
  }
  .Business-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Sidebar-Header-Section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #f1f1f7;
    padding-bottom: 10px;
  }
  .Sidebar-Header-Content {
    width: 100%;
    display: flex;

    flex-direction: column;
  }
  .Sidebar-Search-Box-Section {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .kyc-search {
    width: 200px;
    height: 29px;
    font-size: 13px;
    border: none;
    outline: none;
    color: black;
    background-color: #edeaf4;
  }
  .kyc-search-box {
    display: flex;
    align-items: center;
    background-color: #edeaf4;
    padding: 4px;
    border-radius: 4px;
    margin-left: 20px;
    cursor: pointer;
  }
  .kyc-icon {
    width: 20px;
    height: 18px;
    color: #878a99;
  }

  .Listing-Icon {
    width: 47px;
    height: 30px;
    color: #878a99;
  }
  .Avatar-Main-Section {
    display: flex;
    background-color: #f3f3f9;
    width: 165px;
    height: 64px;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .Avatar-Title {
    font-size: 14px;
    font-weight: 400;

    color: #2d2f39;
  }
  .Avatar-Text {
    font-size: 15px;
    font-weight: 500;
    color: #2d2f39;
  }
  .Manager-Avatar {
    height: 35px;
    width: 24px;
  }
  .Avatar-Text-Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Bell-Main-Section {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 10px;
  }
  .Bell-Icon {
    color: #495057;
    width: 30px;
    height: 25px;
  }
   .Call-Center-Title {
 
 
    color: #2d2f39;
    background-color: #ffffff;
    padding-left: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}
.call-center{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 16px;
}
.Title-Section{
    display: flex;
    align-items: center;

}
.Study-Text{
    font-size: 16px;

}
.Study-icon{
    height: 20px;
    width: 22px;
}
.Study-Title{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
   color: #878A99;
   margin-right: 10px;
}
  .Main-Detail-Box{
    display: flex;
    /* justify-content: center; */
    background-color: white;
    height: 100vh;
    width: 99%;
    flex-direction: column;
    margin-top: 30px;
    border-radius: 5px;
  }
 
 .Main-Content {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin-top: 10px;
}
  .Contact-Title{
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 17px;
    font-weight: 500;
  }
  .Card-Section{
   margin-top: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    flex-wrap: wrap;
    gap: 10px;

  }
  .Card-Main-Section {
    border: 1px solid #F4F5F5;
    width: 252px;
    height: 110px;
    display: flex;
    /* justify-content: center; */
   padding-top: 10px;
    padding-left: 10px;
   
}
.Container{
    display: flex;
    gap: 10px;
 width: 97%;
    flex-direction: column;
 
}
.Title{
font-size: 17px;
font-weight: 600;
color: #878A99;
}
.Title-Main {
gap: 10px;
    display: flex;
  justify-content: space-between;
    align-items: center;
}
.Card-Icon{
    color: #878A99;
}
 .Add-File-button {
    width: 152px;
    display: flex;
    align-items: center;
    height: 39px;
    justify-content: center;
    font-size: 16px;
    background-color: #71C495;
    color: white;
    border: none;
    font-weight: 500;
}
.Card-Title{
    font-size: 16px;
    color: #878A99;
}
.pagination{
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 30px;
  gap: 10px;
  width: 97%;
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination button {
    padding: 5px 15px;
    margin: 0 5px;
    border: 1px solid #ddd;
    background: #f4f4f4;
    cursor: pointer;
  }

  .pagination button.active {
    background-color: #007bff;
    color: white;
  }

  .pagination button:hover {
    background-color: #007bff;
    color: white;
  }
  .Status-Color {
    color: #007bff;
  }
  .Status-Color1 {
    color: red;
  }
  .Client-Color {
    color: #007bff;
  }
  .pagination-container {
    display: flex;
    justify-content: space-between;
    width: 98%;
    align-items: center;
    margin-top: 10px;
  }
  .pagination-text {
    font-size: 15px;
    color: #878a99;
  }
  .pagination-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .page-button {
    width: 88px;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    color: #878a99;
  }
  .page-button1 {
    width: 35px;
    height: 32px;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    background-color: #34437a;
    border: 1px solid #34437a;
  }
  .page-button2 {
    width: 35px;
    height: 32px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    background-color: white;
    border: 1px solid #ced4da;
  }
  .page-button3 {
    width: 58px;
    height: 32px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    background-color: white;
    border: 1px solid #ced4da;
  }
  .page-button:hover {
    background-color: #71C495;
    color: white;
  }
  .page-button2:hover {
    background-color: #71C495;
    color: white;
  }
  .page-button3:hover {
    background-color: #71C495;
    color: white;
  }

`;
export const ReminderStyled = styled.div`

.reminder{
  width: 300px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
position: absolute;
 backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    background-color: hsla(228, 24%, 6%, .2);
    right: 50px;
top: 10px;


border-radius: 12px;
}
.reminder-content {
    display: flex;
    justify-content: space-between;
    width: 90%;
    flex-direction: column;
    gap: 8px;
}
.close {
  position: absolute;
  top: -9px;
  right: 10px;
  cursor: pointer;
  font-size: 30px;
}
.Title-Section{
  display: flex;
  align-items: center;
gap: 5px;
}
.Reminder-Title{
font-weight: 500;
  font-size: 17px;
}
.Icon{
  width: 22px; 
  height: 20px;
}
`;