import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar-2/SideBar"
import { useParams } from 'react-router-dom';
import { StudyStyled } from "./style"
import Logo from "../../../../images/login-logo.png"
import { useNavigate } from "react-router-dom";
import { API } from "../../../../api/api";
import { toast } from "react-toastify";
import { Select } from 'antd';
import { FaTrashAlt } from 'react-icons/fa';
function AddCda() {
    const { Option } = Select;
    const navigate = useNavigate();
    const [studyName, setStudyName] = useState("");
    // const [studiesWithSerialNumber, setStudiesWithSerialNumber] = useState([]);
    const [sponsorName, setSponsorName] = useState("");
    const [cdaExecutionDate, setCdaExecutionDate] = useState("");
    const [cdaExpirationDate, setCdaExpirationDate] = useState("");
    const [file, setFile] = useState(null);
    const user = JSON.parse(localStorage.getItem("user"))

    const handleRemoveFile = () => {
        setFile(null);
        document.getElementById('fileInput').value = '';
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    let { id } = useParams();
    // const MAX_COUNT = Infinity;
    // const suffix = (
    //     <>
    //         <span>{studiesWithSerialNumber.length} / {MAX_COUNT}</span>
    //         <DownOutlined />
    //     </>
    // );
    // useEffect(() => {
    //     getStudyManagerStudies();
    // }, []);
    // const getStudyManagerStudies = async () => {
    //     await API.getStudyManagerStudies({ id: user._id })
    //         .then((resp) => {
    //             if (resp.status == 200) {
    //                 setStudiesWithSerialNumber(resp.data.data);
    //                 // toast.success(resp.data.message);
    //             }
    //         })
    //         .catch((e) => toast.error(e.response.data.message));
    // };
    const onCdaData = async (e) => {
        e.preventDefault();

        if (!sponsorName) {
            return toast.error("Please Enter Sponsor Name");
        }

        const formData = new FormData()
        formData.append('type', "sponsor");
        formData.append('studyName', studyName);
        formData.append('sponsorName', sponsorName);
        formData.append('cdaExecutionDate', cdaExecutionDate);
        formData.append('cdaExpirationDate', cdaExpirationDate);


        if (file) {
            formData.append('file', file);
        }
        await API.createCda(formData)
            .then((resp) => {
                if (resp.status == 200) {
                    toast.success(resp.data.message);

                    navigate("/user-cda-sponsor");
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };


    return (

        <>
            <Sidebar>
                <StudyStyled>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div></div>
                                    <div className="Bell-Main-Section">






                                    </div>
                                </div>

                                <div className="Call-Center-Title">
                                    <span className="call-center">Add Sponsor CDA</span>

                                </div>
                            </div>
                            <div className="Main-Detail-Box">
                                <div className='Header-Form'>


                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Study Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Site Name"
                                                value={studyName}
                                                onChange={(e) => setStudyName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Sponsor Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Site Name"

                                                value={sponsorName}
                                                onChange={(e) => setSponsorName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        Study Name</span>
                                    <div className="FormBox-select">
                                        <Select
                                            className="NameBox-select"
                                            value={studyName}
                                            onChange={(value) => setStudyName(value)}
                                            mode="multiple"
                                            suffixIcon={suffix}
                                            placeholder="Select Study"
                                        >
                                            {studiesWithSerialNumber?.map((item) => (
                                                <Option key={item._id} value={item.studyName}>
                                                    {item.studyName}
                                                </Option>
                                            ))}
                                        </Select>

                                    </div>
                                </div> */}
                                    <div className="Names-Form">

                                        <div className="Names-Form-Last">
                                            <div className="Form-cv">
                                                <span className='Title'>CDA</span>
                                                <input
                                                    type="file"
                                                    className="NameBox-cv"
                                                    name="file"
                                                    id="fileInput"
                                                    onChange={handleFileChange}

                                                />
                                                {file && (
                                                    <div className='RemoveFile' onClick={handleRemoveFile}>
                                                        <FaTrashAlt className='RemoveIcon' />
                                                        <span>Remove selected file</span>
                                                    </div>
                                                )}

                                            </div>


                                        </div>

                                    </div>

                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            CDA Execution Date</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="date"
                                                placeholder="Enter PI Email"
                                                value={cdaExecutionDate}

                                                onChange={(e) => setCdaExecutionDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            CDA Expiration Date </span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="date"
                                                placeholder="Enter Specialty"
                                                value={cdaExpirationDate}

                                                onChange={(e) => setCdaExpirationDate(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="pagination">
                                    <button className='Button-1' onClick={() => navigate("/user-cda-sponsor")}>Cancel</button>
                                    <button className="Button-2" onClick={(e) => onCdaData(e)}>Submit</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </StudyStyled>
            </Sidebar >
        </>
    )
}


export default AddCda