import React, { useState, useEffect } from 'react';
import Sidebar from "../../../Sidebar-2/SideBar"
import { useParams } from 'react-router-dom';
import { StudyStyled } from "./style"
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { API } from "../../../../api/api";
import { toast } from "react-toastify";

function AddSiteSubmisson() {
    const navigate = useNavigate();


    const [cv, setCv] = useState(null);
    const [medicalCertificate, setMedicalCertificate] = useState(null);
    const [file, setFile] = useState(null);
    const [submission, setSubmisson] = useState({});
    const [allApprovedSiteSubmissions, setAllApprovedSiteSubmissions] = useState({});
    const [isReadyForPISubmission, setisReadyForPISubmission] = useState("");
    const [studyManagerComment, setStudyManagerComment] = useState("");
    const [isReadyForSiteSubmissionVisit, setIsReadySiteSubmissionVisit] = useState("");
    let { id } = useParams();

    const handleStudyManagerCommentChange = (e) => {
        const updatePISiteSubmission = { ...submission, studyManagerComment: e.target.value };
        setSubmisson(updatePISiteSubmission);
    };
    const handleActiveChange = (e) => {
        const isChecked = e.target.checked;
        const updatePISiteSubmission = { ...submission, active: isChecked };
        console.log(submission.active);
        setSubmisson(updatePISiteSubmission);
    };
    const handleIsReadyForPISubmissionChange = (e) => {
        const isChecked = e.target.checked;
        const updatePISiteSubmission = { ...submission, isReadyForPISubmission: isChecked };
        console.log(submission.isReadyForPISubmission);
        setSubmisson(updatePISiteSubmission);
    };
    const handleIsReadySiteSubmissionVisitChange = (e) => {
        const isChecked = e.target.checked;
        const updatePISiteSubmission = { ...submission, isReadyForSiteSubmissionVisit: isChecked };
        setSubmisson(updatePISiteSubmission);
    };
    useEffect(() => {
        getSubmisson(id);
    }, []);
    const getSubmisson = async (id) => {

        await API.getSubmisson(id,
        )
            .then((resp) => {
                console.log();
                if (resp.status == 200) {
                    setSubmisson(resp.data.data);
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };

    const handleRemoveCV = () => {
        setCv(null);
        document.getElementById('NameBoxCV').value = '';
    };

    const handleRemoveCertificate = () => {
        setMedicalCertificate(null);
        document.getElementById('NameBoxCertificate').value = '';
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    console.log(submission)
    const updatePISiteSubmission = async () => {
        allApprovedSiteSubmissions.id = allApprovedSiteSubmissions._id;
        const formData = new FormData()
        formData.append('id', submission._id);
        formData.append('studyManagerComment', submission.studyManagerComment);
        formData.append('isReadyForPISubmission', submission.isReadyForPISubmission);
        formData.append('active', submission.active);
        formData.append('isReadyForSiteSubmissionVisit', submission.isReadyForSiteSubmissionVisit);
        if (cv) {
            formData.append('files', cv, medicalCertificate);
        }
        if (medicalCertificate) {
            formData.append('files', cv, medicalCertificate);
        }
        await API.updatePISiteSubmission(formData)

            .then((resp) => {
                // console.log();
                if (resp.status == 200) {
                    // console.log(resp.data.data);
                    // setLead(resp.data.data);
                    getSubmisson(id);
                    navigate("/user-pi-submisson");
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };



    return (

        <>
            <Sidebar>
                <StudyStyled>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">

                            <div className="Main-Detail-Box">
                                <div className='Boxes-Container
                                '>

                                    <div className="Names-Form">
                                        <div className="Names-Form-Last">
                                            <div className="Form-cv">
                                                <span className='Title'>CV</span>

                                                <input
                                                    className="NameBoxCV"
                                                    type="file"
                                                    name='file'
                                                    onChange={(e) => setCv(e.target.files[0])}
                                                    id="NameBoxCV"
                                                />
                                                {cv && (
                                                    <div className='RemoveFile' onClick={handleRemoveCV}>
                                                        <FaTrashAlt className='RemoveIcon' />
                                                        <span>Remove selected file</span>
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="Names-Form-Last">
                                            <div className="Form-cv">
                                                <span className='Title'>Medical Certificite</span>

                                                <input
                                                    className="NameBoxCertificate"
                                                    type="file"
                                                    name='file'
                                                    onChange={(e) => setMedicalCertificate(e.target.files[0])}
                                                    id='NameBoxCertificate'
                                                />
                                                {medicalCertificate && (
                                                    <div className='RemoveFile' onClick={handleRemoveCertificate}>
                                                        <FaTrashAlt className='RemoveIcon' />
                                                        <span>Remove selected file</span>
                                                    </div>
                                                )}
                                            </div>


                                        </div>

                                    </div>
                                    <div className="Names-Form-checkbox">
                                        <div className='Check-Box-Containers'>
                                            <div className='Check-Box-Section'>
                                                <input
                                                    className="Checkbox"
                                                    type="checkbox"
                                                    checked={submission.isReadyForPISubmission}
                                                    onChange={handleIsReadyForPISubmissionChange}
                                                />
                                                <span className='Title'>  Ready for PI Submisson</span>
                                            </div>
                                            <div className='Check-Box-Section'>
                                                <input
                                                    className="Checkbox"
                                                    type="checkbox"
                                                    checked={submission.active}
                                                    onChange={handleActiveChange}
                                                />
                                                <span className='Title'>  Active</span>

                                            </div>
                                            <div className='Check-Box-Section'>
                                                <input
                                                    className="Checkbox"
                                                    type="checkbox"
                                                    checked={submission.isReadyForSiteSubmissionVisit}
                                                    onChange={handleIsReadySiteSubmissionVisitChange}
                                                />
                                                <span className='Title'>  Ready for Site Selection Visit</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Names-Form">
                                        <div className="Icons-Section">
                                            <div className="Form-Box-Text-Bottom">
                                                <span className="Form-Box-Title">
                                                    Study Manager Comment</span>
                                                <div className="FormBox-Comment">

                                                    <textarea
                                                        className="NameBox-Comment"
                                                        placeholder="Study Manager Comment"

                                                        value={
                                                            submission.studyManagerComment ? submission.studyManagerComment : studyManagerComment
                                                        }
                                                        onChange={handleStudyManagerCommentChange}

                                                        rows={5}
                                                    ></textarea>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="pagination">
                                    <button className='Button-1' onClick={() => navigate("/user-pi-submisson")}>Cancel</button>
                                    <button className="Button-2" onClick={(e) => updatePISiteSubmission(e)}>Submit</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </StudyStyled >
            </Sidebar>
        </>
    )
}


export default AddSiteSubmisson