import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";
import Sidebar from "../../Sidebar-2/SideBar";
import { ReminderStyled, Partnerstyled, ModalStyled, ModalFormstyled } from "./style";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { TbEdit } from "react-icons/tb";
import { API } from "../../../api/api";
import { IoPersonSharp } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { FcAlarmClock } from "react-icons/fc";
import { toast } from "react-toastify";

function PiSubmisson() {
    const userData = JSON.parse(localStorage.getItem("user"));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reminders, setReminders] = useState([]);
    const navigate = useNavigate();
    const handleClose = () => setIsModalOpen(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const [search, setSearch] = useState('')
    const [snpa, setSnpa] = useState()
    const [status, setStatus] = useState("")

    const [currentPage, setCurrentPage] = useState(1);
    function formatDate(dateString) {
        // Create a new Date object from the dateString
        const date = new Date(dateString);

        // Format the date as per your requirements
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",

        });

        return formattedDate;
    }
    function formatDate1(dateString) {
        // Create a new Date object from the dateString
        const date = new Date(dateString);

        // Format the date as per your requirements
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
        });

        return formattedDate;
    }
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const userId = user._id;
        getAllReminder();
    }, []);
    function ReminderComponent({ reminder }) {
        return (
            <ReminderStyled>
                <div className="reminder">
                    <div className="reminder-content">
                        <div className="Title-Section">

                            <p className="Reminder-Title">
                                {reminder.title}</p>

                        </div>
                        <div className="Title-Section">
                            <FcAlarmClock className="Icon" />
                            <p className="Reminder-Title">
                                {formatDate1(reminder.dateAndTime)}
                            </p>

                        </div>

                        <span className="close" onClick={() => closeReminder(reminder._id)}>&times;</span>
                    </div>
                </div>
            </ReminderStyled>
        );
    }


    const getAllReminder = async () => {
        try {
            const response = await API.getAllUserReminder({


            });
            const responseData = response.data;

            if (responseData && responseData.success) {
                setReminders(responseData.data);

            } else {
                // toast.error("Failed to fetch companies");
            }
        } catch (error) {
            // toast.error("Error fetching submissons: " + error.message);
        }
    };
    const closeReminder = async (id) => {
        // socket.emit("toggleReminder", reminderId, true);

        // preventDefault();
        await API.turnOffRemainder(
            id,
        )
            .then((resp) => {
                // console.log(resp);
                if (resp.status == 200) {
                    toast.success(resp.data.message);
                    // console.log(resp.data.data);
                    setReminders(reminders.filter(reminder => reminder._id !== id));
                }
            })
            .catch((e) => toast.error(e.response.data.message));


    };
    const handleDownload = () => {
        // Prepare data for Excel export
        const data = allsiteSubmisson.map((item, index) => ({
            "Sl.No": index + 1,
            "Site Name": item.name,
            Address: item.address,
            "PI Name": item.piName,
            "PI Specialty": item.specialty,
            Comment: item.studyManagerComment,
            UpdatedCV: item.updatedCV,
            MedicalCertificate: item.medicalCertificate,
        }));

        // Convert data to Excel workbook
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "PI_Submissions");

        // Generate Excel file and initiate download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const file = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(file, "PI_Submissions.xlsx");
    };
    const [allsiteSubmisson, setAllSiteSubmissons] = useState([]);
    const Modal = ({ isOpen, closeModal }) => {
        if (!isOpen) return null;
        return (
            <ModalStyled>
                <div className="modal-overlay" onClick={() => handleCancel()}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="Closing-Section">
                            <span className="Title">Filter</span>
                            <span className="close" onClick={() => handleClose()}>&times;</span>
                        </div>
                        <ModalFormstyled>

                            <div className="Main-Detail-Box-1">
                                <div className="Names-Form-1">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">Filters</span>
                                        <div className="FormBox">
                                            <IoMdSearch className="search-icon" />
                                            <input
                                                className="NameBox"
                                                type="text"
                                                placeholder="Search"
                                                value={search}
                                                onChange={handleSearchChange}
                                            />

                                        </div>
                                    </div>
                                    <div className="Icons-Section">
                                        <p>SNPA</p>
                                        <div className="status-icons">
                                            <span className={`icon ${snpa == true ? 'highlight' : ''} success`} onClick={() => setSnpa(true)}>✔</span>
                                            <span className={`icon ${snpa == false ? 'highlight' : ''} error`} onClick={() => setSnpa(false)}>✖</span>
                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">Status</span>
                                        <div className="FormBox">
                                            <select className="NameBox"
                                                onChange={(e) => setStatus(e.target.value)}
                                            >

                                                <option value="">Select Status</option>
                                                <option>Feasibility</option>
                                                <option>Budget / Contract = Regulatory</option>
                                                <option>SSV - Selected</option>
                                                <option>SSV - Not Selected</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="pagination-buttons-modal">
                                    <button className="Button-1" onClick={() => handleCancel()}>Cancel</button>
                                    <button className="Button-2" onClick={() => getAllApprovedSearchSites()}>Submit</button>
                                </div>
                            </div>

                        </ModalFormstyled>
                    </div>
                </div>
            </ModalStyled>
        );
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getAllApprovedSiteSubmissions();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [currentPage]);


    useEffect(() => {
        getAllApprovedSiteSubmissions(currentPage);
    }, [currentPage]);

    const getAllApprovedSiteSubmissions = async () => {
        try {
            const response = await API.getAllApprovedUsersSiteSubmissions({
                page: currentPage,


            });
            const responseData = response.data;

            if (responseData && responseData.success) {
                setAllSiteSubmissons(responseData.data.siteSubmissions);

            } else {
                // toast.error("Failed to fetch companies");
            }
        } catch (error) {
            // toast.error("Error fetching submissons: " + error.message);
        }
    };
    const handleCancel = async () => {
        setSearch('')
        setSnpa('')
        setStatus('')
        await getAllApprovedSiteSubmissions()
        handleClose()

    }

    const getAllApprovedSearchSites = async () => {
        try {
            const response = await API.getAllApprovedUsersSiteSubmissions({
                search,
                snpa,
                status

            });
            const responseData = response.data;

            if (responseData && responseData.success) {
                setAllSiteSubmissons(responseData.data.siteSubmissions);

            } else {
                // toast.error("Failed to fetch companies");
            }
        } catch (error) {
            // toast.error("Error fetching submissons: " + error.message);
        }
    };



    function shortenText(name) {
        console.log(name)
        if (name?.length <= 10) {
            return name;
        }
        return name?.slice(0, 10) + '...';
    }

    return (
        <>
            <Sidebar>
                <Partnerstyled>
                    <div>
                        {reminders?.map((reminder) => (
                            <ReminderComponent
                                key={reminder._id}
                                reminder={reminder}
                                onClose={closeReminder}
                            />
                        ))}
                    </div>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div className="Sidebar-Search-Box-Section">

                                    </div>
                                    <div className="Bell-Main-Section">

                                        <div className="Avatar-Main-Section">
                                            <IoPersonSharp className="Manager-Avatar" />
                                            <div className="Avatar-Text-Section">
                                                <span className="Avatar-Title">
                                                    {userData.userName}

                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Call-Center-Title">
                                    <span className="call-center">
                                        PI Submission</span>
                                </div>
                            </div>
                            <div className="Parter-Main-Section">
                                <div className="Partner-Container">
                                    <div className="Partner-Form-Header">
                                        <div className="Header-Text">
                                            <span>
                                            </span>
                                        </div>
                                        <div className="Header-Button-Section">
                                            <button className="Comment-Button" onClick={handleDownload}>
                                                Download
                                            </button>
                                            <button className="Comment-Button" onClick={toggleModal}>
                                                Filter
                                            </button>
                                            <Modal
                                                isOpen={isModalOpen}
                                                closeModal={toggleModal}

                                            />
                                        </div>
                                    </div>
                                    <div className="Name-Filter-Box1">
                                        <div className="kyc-search-box1">


                                        </div>
                                    </div>
                                    <div className="Table-Scrolling">
                                        <div className="Table-Section">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Sl.No
                                                        </th>
                                                        <th>Site Name
                                                        </th>
                                                        <th>Address</th>
                                                        <th>PI Name
                                                        </th>
                                                        <th>PI Specialty
                                                        </th>
                                                        <th>Comment</th>
                                                        <th>Updated CV</th>
                                                        <th>Updated Medical Certificate</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allsiteSubmisson?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td >{item.siteName}</td>
                                                            <td>{item.address}</td>
                                                            <td>{item.piName}</td>
                                                            <td>{item.specialty}</td>
                                                            <td>
                                                                {item.studyManagerComment === "" || !item.studyManagerComment ? (
                                                                    <button
                                                                        className="Comment-Button"
                                                                        onClick={() => navigate(`/user-update-Pi/${item._id}`)}
                                                                    >
                                                                        Add Comment
                                                                    </button>
                                                                ) : (
                                                                    item.studyManagerComment
                                                                )}
                                                            </td>
                                                            <td>
                                                                <a class="Comment-Button" href={item.updatedCV} download="CV.pdf">{shortenText(item.updatedCVName, 5)}</a>
                                                            </td>
                                                            <td>
                                                                <a class="Comment-Button" href={item.medicalCertificate} download="MediaclCertificate.pdf"> {shortenText(item.medicalCertificateName, 5)}</a>

                                                            </td>
                                                            <td>
                                                                <div className="Icons-Gapping">
                                                                    <TbEdit
                                                                        className="Table-Icons"

                                                                        onClick={() => navigate(`/user-update-Pi/${item._id}`)}

                                                                        key={index}
                                                                    />
                                                                    {/* <MdOutlineDelete
                                                                        onClick={(e) => handleDelete(e, item._id)}
                                                                        className="Table-Icons1"
                                                                    /> */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="pagination-container">
                                    <span className="pagination-text">

                                    </span>
                                    <div className="pagination-buttons">
                                        <button
                                            className="page-button"
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        {Array.from(
                                            Array(allsiteSubmisson?.pagination?.totalPages).keys()
                                        ).map((pageNumber) => (
                                            <button
                                                key={pageNumber}
                                                className={`page-button${currentPage === pageNumber + 1 ? " active" : ""
                                                    }`}
                                                onClick={() => setCurrentPage(pageNumber + 1)}
                                            >
                                                {pageNumber + 1}
                                            </button>
                                        ))}
                                        <button
                                            className="page-button"
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            disabled={
                                                currentPage === allsiteSubmisson?.pagination?.totalPages
                                            }
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Partnerstyled>
            </Sidebar>
        </>
    );
}

export default PiSubmisson