import styled from "styled-components";

export const StudyStyled = styled.div`
display: flex;
width: 100%;
background-color: #F3F3F9;
.Business-Main-Section {
    width: 100%;
    display: flex;
    flex-direction: column;

    height: 100vh;
    overflow: auto;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  .Business-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Sidebar-Header-Section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #f1f1f7;
    padding-bottom: 10px;
  }
  .Sidebar-Header-Content {
    width: 100%;
    display: flex;

    flex-direction: column;
  }
  .Sidebar-Search-Box-Section {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .kyc-search {
    width: 200px;
    height: 29px;
    font-size: 13px;
    border: none;
    outline: none;
    color: black;
    background-color: #edeaf4;
  }
  .kyc-search-box {
    display: flex;
    align-items: center;
    background-color: #edeaf4;
    padding: 4px;
    border-radius: 4px;
    margin-left: 20px;
    cursor: pointer;
  }
  .kyc-icon {
    width: 20px;
    height: 18px;
    color: #878a99;
  }

  .Listing-Icon {
    width: 47px;
    height: 30px;
    color: #878a99;
  }
  .Avatar-Main-Section {
    display: flex;
    background-color: #f3f3f9;
    width: 165px;
    height: 64px;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .Avatar-Title {
    font-size: 14px;
    font-weight: 400;

    color: #2d2f39;
  }
  .Avatar-Text {
    font-size: 15px;
    font-weight: 500;
    color: #2d2f39;
  }
  .Manager-Avatar {
    height: 35px;
    width: 24px;
  }
  .Avatar-Text-Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Bell-Main-Section {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 10px;
  }
  .Bell-Icon {
    color: #495057;
    width: 30px;
    height: 25px;
  }
   .Call-Center-Title {
 
 
    color: #2d2f39;
    background-color: #ffffff;
    padding-left: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}
.call-center{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 16px;
}
.Title-Section{
    display: flex;
    align-items: center;

}
.Study-Text{
    font-size: 16px;

}
.Study-icon{
    height: 20px;
    width: 22px;
}
.Study-Title{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
   color: #878A99;
   margin-right: 10px;
}
  .Main-Detail-Box{
    display: flex;
    /* justify-content: center; */
    background-color: white;
    height: 100vh;
    width: 99%;
    flex-direction: column;
    margin-top: 30px;
    border-radius: 5px;
  }
.Names-Form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
}
.Form-Box-Text-Bottom{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}
.Form-Box-Title-1{
  padding-left: 11px;
}
  .NameBox {
    width: 360px;
    height: 40px;
    font-size: 17px;
    border: none;
    padding-left: 10px;
    outline: none;
    color: black;
  }
  .FormBox {
    border: 2px #e6ebf2 solid;
    padding: 4px;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 370px;
  }
  
  .FormBox-select{

cursor: pointer;
  }
  
  .NameBox-select {
    width: 395px;
    height: 70px;
    overflow: auto;
    font-size: 17px;
    border: none;
    padding-left: 10px;
    outline: none;
    color: black;
  }
.Card-Title{
    font-size: 16px;
    color: #878A99;
}
.pagination{
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
  width: 100%;
}

  .Button-2 {
    width: 365px;
    cursor: pointer;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #71C495;
    font-size: 15px;
    font-weight: 500;
    color: white;
  }
  .Button-1{
    width: 365px;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    font-size: 15px;
    font-weight: 500;
   color: black;
  }
  .Button-1:hover{
background-color: #71C495;
color: white
;
  }
  .Button-2:hover{
background-color: #71C495;
}
:where(.css-dev-only-do-not-override-1r287do).ant-select .ant-select-arrow {
    display: none;
    align-items: center;
    color: rgba(0, 0, 0, 0.25);
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    inset-inline-start: auto;
    inset-inline-end: 11px;
    height: 12px;
    margin-top: -6px;
    font-size: 12px;
    pointer-events: none;
    transition: opacity 0.3s ease;
}
.ant-select-show-search:where(.css-dev-only-do-not-override-1r287do).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 58px;
    cursor: text;
    width: 370px;
    font-size: 17px;
    border: 2px #e6ebf2 solid;
    border-radius: 9px;
    outline: none;
    color: black;
}
.ant-select-focused:where(.css-dev-only-do-not-override-1r287do).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color:#e6ebf2 !important; 
    /* box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1); */
    outline: 0;
}
`;