import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoPersonSharp } from "react-icons/io5";
import Sidebar from "../../Sidebar-2/SideBar";
import { TbEdit } from "react-icons/tb";
import { MdOutlineDelete } from "react-icons/md";
import { useParams } from "react-router-dom";
import { API } from "../../../api/api";
import { toast } from "react-toastify";
import { Partnerstyled, ReminderStyled, ModalStyled, ModalFormstyled } from "./style";
import { IoMdSearch } from "react-icons/io";
import { Table } from 'antd';
import { FcAlarmClock } from "react-icons/fc";

function UserSubmisson() {
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("user"));
    let { id } = useParams();
    const [reminders, setReminders] = useState([]);
    const [allUserSubmissionsWithStudies, setAllUserSubmissionsWithStudies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const handleClose = () => setIsModalOpen(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const [search, setSearch] = useState('')
    const [snpa, setSnpa] = useState()
    const [status, setStatus] = useState("")
    console.log(status, snpa, search)
    const [isModalOpen, setIsModalOpen] = useState(false);
    function formatDate(dateString) {
        // Create a new Date object from the dateString
        const date = new Date(dateString);

        // Format the date as per your requirements
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",

        });

        return formattedDate;
    }

    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const userId = user._id;
        getAllReminder();
    }, []);
    function ReminderComponent({ reminder }) {
        return (
            <ReminderStyled>
                <div className="reminder">
                    <div className="reminder-content">
                        <div className="Title-Section">

                            <p className="Reminder-Title">
                                {reminder.title}</p>

                        </div>
                        <div className="Title-Section">
                            <FcAlarmClock className="Icon" />
                            <p className="Reminder-Title">
                                {formatDate(reminder.dateAndTime)}
                            </p>

                        </div>

                        <span className="close" onClick={() => closeReminder(reminder._id)}>&times;</span>
                    </div>
                </div>
            </ReminderStyled>
        );
    }


    const getAllReminder = async () => {
        try {
            const response = await API.getAllUserReminder({


            });
            const responseData = response.data;

            if (responseData && responseData.success) {
                setReminders(responseData.data);

            } else {
                // toast.error("Failed to fetch companies");
            }
        } catch (error) {
            // toast.error("Error fetching submissons: " + error.message);
        }
    };
    const closeReminder = async (id) => {
        // socket.emit("toggleReminder", reminderId, true);

        // preventDefault();
        await API.turnOffRemainder(
            id,
        )
            .then((resp) => {
                // console.log(resp);
                if (resp.status == 200) {
                    toast.success(resp.data.message);
                    // console.log(resp.data.data);
                    setReminders(reminders.filter(reminder => reminder._id !== id));
                }
            })
            .catch((e) => toast.error(e.response.data.message));


    };
    const Modal = ({ isOpen, closeModal }) => {
        if (!isOpen) return null;
        return (
            <ModalStyled>
                <div className="modal-overlay" onClick={() => handleCancel()}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="Closing-Section">
                            <span className="Title">Filter</span>
                            <span className="close" onClick={() => handleClose()}>&times;</span>
                        </div>
                        <ModalFormstyled>
                            <form className="Main-Detail-Box-1">
                                <div className="Main-Detail-Box-1">
                                    <div className="Names-Form-1">
                                        <div className="Form-Box-Text-Bottom">
                                            <span className="Form-Box-Title">Filters</span>
                                            <div className="FormBox">
                                                <IoMdSearch className="search-icon" />
                                                <input
                                                    className="NameBox"
                                                    type="search"
                                                    placeholder="Search"
                                                    value={search}
                                                    onChange={handleChange}
                                                />

                                            </div>
                                        </div>
                                        <div className="Icons-Section">
                                            <p>SNPA</p>
                                            <div className="status-icons">
                                                <span className={`icon ${snpa == true ? 'highlight' : ''} success`} onClick={() => setSnpa(true)}>✔</span>
                                                <span className={`icon ${snpa == false ? 'highlight' : ''} error`} onClick={() => setSnpa(false)}>✖</span>
                                            </div>
                                        </div>
                                        <div className="Form-Box-Text-Bottom">
                                            <span className="Form-Box-Title">Status</span>
                                            <div className="FormBox">
                                                <select className="NameBox"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >

                                                    <option value="">Select Status</option>
                                                    <option>Feasibility</option>
                                                    <option>Budget / Contract = Regulatory</option>
                                                    <option>SSV - Selected</option>
                                                    <option>SSV - Not Selected</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pagination-buttons-modal">
                                        <button className="Button-1" onClick={(e) => handleCancel(e)}>Cancel</button>
                                        <button className="Button-2" onClick={(e) => getAllApprovedSearchSites(e)}>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </ModalFormstyled>
                    </div>
                </div>
            </ModalStyled>
        );
    };
    function formatDate(dateString) {

        const date = new Date(dateString);


        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true, // If you want 12-hour format
        });

        return formattedDate;
    }
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getAllUserSubmissionsWithStudies();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [currentPage]);


    useEffect(() => {
        getAllUserSubmissionsWithStudies(currentPage);
    }, [currentPage]);



    const getAllUserSubmissionsWithStudies = async () => {

        try {

            const response = await API.getAllUserSubmissionsWithStudies({
                user: id,
                page: currentPage,


            });
            const responseData = response.data;

            if (responseData && responseData.success) {
                setAllUserSubmissionsWithStudies(responseData.data.siteSubmissions);

            } else {
                // toast.error("Failed to fetch companies");
            }
        } catch (error) {
            // toast.error("Error fetching submissons: " + error.message);
        }
    };
    const handleCancel = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setSearch('');
        setSnpa('');
        setStatus('');
        await getAllUserSubmissionsWithStudies();
        handleClose();

    }

    const getAllApprovedSearchSites = async (e) => {
        e.preventDefault();
        try {
            const response = await API.getAllUserSubmissionsWithStudies({
                user: id,
                search,
                snpa,
                status

            });
            const responseData = response.data;
            if (responseData && responseData.success) {
                setAllUserSubmissionsWithStudies(responseData.data.siteSubmissions);

            } else {
                // toast.error("Failed to fetch companies");
            }
        } catch (error) {
            // toast.error("Error fetching submissons: " + error.message);
        }
    };

    function shortenText(name) {
        console.log(name)
        if (name?.length <= 10) {
            return name;
        }
        return name?.slice(0, 10) + '...';
    }



    return (
        <>
            <Sidebar>
                <Partnerstyled>
                    <div>
                        {reminders?.map((reminder) => (
                            <ReminderComponent
                                key={reminder._id}
                                reminder={reminder}
                                onClose={closeReminder}
                            />
                        ))}
                    </div>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div className="Sidebar-Search-Box-Section">

                                    </div>
                                    <div className="Bell-Main-Section">

                                        <div className="Avatar-Main-Section">
                                            <IoPersonSharp className="Manager-Avatar" />
                                            <div className="Avatar-Text-Section">
                                                <span className="Avatar-Title">
                                                    {userData.userName}

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Call-Center-Title">
                                    <span className="call-center">
                                        User Study Submissions</span>
                                </div>
                            </div>
                            <div className="Parter-Main-Section">
                                <div className="Partner-Container">
                                    <div className="Partner-Form-Header">
                                        <div className="Header-Text">
                                            <span>
                                            </span>
                                        </div>
                                        <div className="Header-Button-Section">
                                            <button className="Comment-Button" onClick={toggleModal}>
                                                Filter
                                            </button>
                                            <Modal
                                                isOpen={isModalOpen}
                                                closeModal={toggleModal}

                                            />
                                        </div>
                                    </div>
                                    <div className="Name-Filter-Box1">
                                        <div className="kyc-search-box1">


                                        </div>
                                    </div>
                                    <div className="Table-Scrolling">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sl.No
                                                    </th>
                                                    <th>Study Name
                                                    </th>
                                                    <th>Address</th>
                                                    <th>PI Name
                                                    </th>
                                                    <th>PI Specialty
                                                    </th>
                                                    <th>CRC Name</th>
                                                    <th>CRC Email</th>
                                                    <th>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allUserSubmissionsWithStudies?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td >{item.studyName}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.piName}</td>
                                                        <td>{item.specialty}</td>
                                                        <td>{item.crcName}</td>
                                                        <td>{item.crcEmail}</td>
                                                        <td>{formatDate(item.createdAt)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <div className="pagination-container">
                                    <span className="pagination-text">

                                    </span>
                                    <div className="pagination-buttons">
                                        <button
                                            className="page-button"
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        {Array.from(
                                            Array(allUserSubmissionsWithStudies?.pagination?.totalPages).keys()
                                        ).map((pageNumber) => (
                                            <button
                                                key={pageNumber}
                                                className={`page-button${currentPage === pageNumber + 1 ? " active" : ""
                                                    }`}
                                                onClick={() => setCurrentPage(pageNumber + 1)}
                                            >
                                                {pageNumber + 1}
                                            </button>
                                        ))}
                                        <button
                                            className="page-button"
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            disabled={
                                                currentPage === allUserSubmissionsWithStudies?.pagination?.totalPages
                                            }
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Partnerstyled>
            </Sidebar>
        </>
    );
}

export default UserSubmisson