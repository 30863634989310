import { NavLink, useNavigate } from "react-router-dom";
import { IoSpeedometerSharp } from "react-icons/io5";
import { RiFileList3Fill, RiParentFill } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";
import { IoMdPerson } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { Sidebarstyled } from "./style";
import Sidebarlogo from "../../images/login-logo.png";
import { useParams } from "react-router-dom";

const routes = [
  {
    path: "/user-manager-studies",
    name: "Dashboard",
    icon: <IoSpeedometerSharp />,
  },

  {
    path: "/user-pi-submisson",
    name: "PI Submisson Sheet",
    icon: <RiFileList3Fill />,

  },

  {
    path: "/user-ssv",
    name: "Site Selection Visit ",
    icon: <RiParentFill />,
  },
  {
    path: "/user-budget-contract",
    name: "Budget/Contract",
    icon: <RiParentFill />,
  },
  {
    path: "/user-in-active-submissons",
    name: "Archived Studies",
    icon: <RiFileList3Fill />,

  },
  {
    path: "/user-invetory",
    name: "Inventory ",
    icon: <RiFileList3Fill />,
    subRoutes: [
      {
        path: "/user-invetory",
        name: "PI CV & ML ",
        icon: "-",
      },
      {
        path: "/user-snpa",
        name: "SNPA ",
        icon: "-",
      }, {
        path: "/user-cda-sponsor",
        name: "Sponser CDA",
        icon: "-",
      },
      {
        path: "/user-cda",
        name: "Site CDA ",
        icon: "-",
      },
    ],

  },

  {
    path: "/user-reminder",
    name: "Reminder",
    icon: <IoMdPerson />,
  },
  {
    path: "/user-setting",
    name: "Setting",
    icon: <IoMdSettings />,
  },
  {
    path: "/",
    name: "Log Out",
    icon: <MdLogout />
  }
];

const SideBar = ({ children }) => {

  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/");
  };
  // const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (

    <>
      <Sidebarstyled>
        <div className="main-container">
          <motion.div
            animate={{
              width: isOpen ? "340px" : "45px",

              transition: {
                duration: 0.5,
                type: "spring",
                damping: 10,
              },
            }}
            className={`sidebar `}
          >
            <div className="top_section">
              <AnimatePresence>
                {isOpen && (
                  <motion.h1
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                  >
                    <img className="Sidebar-Logo" src={Sidebarlogo} alt="" />
                  </motion.h1>
                )}
              </AnimatePresence>

              {/* <div className="bars">
                <FaBars onClick={toggle} />
              </div> */}
            </div>

            <section className="routes">
              {routes.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    activeClassName="active"
                    onClick={route.name === "Log Out" ? handleLogout : undefined}
                  >
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
            </section>
          </motion.div>

          <main>{children}</main>
        </div>
      </Sidebarstyled>
    </>
  );
};

export default SideBar;
