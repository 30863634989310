import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoPersonSharp } from "react-icons/io5";
import Sidebar from "../Sidebar/SideBar";
import { Partnerstyled } from "./style";
import { useParams } from "react-router-dom";
import { API } from "../../api/api";
import { toast } from "react-toastify";
// import { Table2Array } from "../../Array";

function UserSubmisson() {
    const userData = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();
    let { id } = useParams();
    const [allsiteSubmisson, setAllSiteSubmissons] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    function formatDate(dateString) {
        // Create a new Date object from the dateString
        const date = new Date(dateString);

        // Format the date as per your requirements
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true, // If you want 12-hour format
        });

        return formattedDate;
    }
    useEffect(() => {
        getAllUserSiteSubmissions();
    }, []);

    const getAllUserSiteSubmissions = async () => {

        await API.getAllUserSiteSubmissions({
            user: id,
        })
            .then((resp) => {
                console.log(resp.data.data)
                if (resp.status == 200) {
                    setAllSiteSubmissons(resp.data.data);

                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };

    return (
        <>
            <Sidebar>
                <Partnerstyled>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div className="Sidebar-Search-Box-Section">

                                    </div>
                                    <div className="Bell-Main-Section">

                                        <div className="Avatar-Main-Section">
                                            <IoPersonSharp className="Manager-Avatar" />
                                            <div className="Avatar-Text-Section">
                                                <span className="Avatar-Title">
                                                    {userData.userName}

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Call-Center-Title">
                                    <span className="call-center">
                                        User Site Submissions</span>
                                </div>
                            </div>
                            <div className="Parter-Main-Section">
                                <div className="Partner-Container">
                                    <div className="Partner-Form-Header">
                                        <div className="Header-Text">
                                            <span>
                                                USS</span>
                                        </div>
                                        <div className="Header-Button-Section">

                                        </div>
                                    </div>
                                    <div className="Name-Filter-Box1">
                                        <div className="kyc-search-box1">


                                        </div>
                                    </div>
                                    <div className="Table-Scrolling">
                                        <div className="Table-Section">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Sl.No
                                                        </th>
                                                        <th> Study Name

                                                        </th>
                                                        <th>Address</th>
                                                        <th>PI Name
                                                        </th>
                                                        <th>PI Email
                                                        </th>
                                                        <th>Specialty
                                                        </th>
                                                        <th>CRC Name</th>
                                                        <th>CRC Email
                                                        </th>
                                                        <th>Date
                                                        </th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allsiteSubmisson.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td >{item.studyName}</td>
                                                            <td>{item.address}</td>
                                                            <td>{item.piName}</td>
                                                            <td>{item.piEmail}</td>
                                                            <td>{item.specialty}</td>
                                                            <td>{item.crcName}</td>
                                                            <td>{item.crcEmail}</td>
                                                            <td>{formatDate(item.createdAt)}</td>


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div></div>
                                    <div className="pagination-container">
                                        <span className="pagination-text">

                                        </span>
                                        <div className="pagination-buttons">
                                            <button
                                                className="page-button"
                                                onClick={() => setCurrentPage(currentPage - 1)}
                                                disabled={currentPage === 1}
                                            >
                                                Previous
                                            </button>
                                            {Array.from(
                                                Array(allsiteSubmisson?.pagination?.totalPages).keys()
                                            ).map((pageNumber) => (
                                                <button
                                                    key={pageNumber}
                                                    className={`page-button${currentPage === pageNumber + 1 ? " active" : ""
                                                        }`}
                                                    onClick={() => setCurrentPage(pageNumber + 1)}
                                                >
                                                    {pageNumber + 1}
                                                </button>
                                            ))}
                                            <button
                                                className="page-button"
                                                onClick={() => setCurrentPage(currentPage + 1)}
                                                disabled={
                                                    currentPage === allsiteSubmisson?.pagination?.totalPages
                                                }
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Partnerstyled>
            </Sidebar>
        </>
    );
}

export default UserSubmisson