import React, { useEffect, useState } from "react";
import { API } from "../../../../api/api";
import { toast } from "react-toastify";
import Sidebar from "../../../Sidebar/SideBar"
import { StudyStyled } from "./style"
import { IoPersonSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import { Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const { Option } = Select;

function AddStudy() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const [studies, setStudies] = React.useState("");

  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [allStudy, setAllStudy] = useState([]);
  const [selectstudy, setSelectstudy] = useState([]);
  const MAX_COUNT = Infinity;

  const suffix = (
    <>
      <span>{studies.length} / {MAX_COUNT}</span>
      <DownOutlined />
    </>
  );
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  useEffect(() => {
    getAllStudy();
  }, []);

  const onsignupData = async (e) => {
    e.preventDefault();

    if (!userName) {
      return toast.error("Please Enter User Name");
    }
    if (!email) {
      return toast.error("Please Enter Email");
    }
    if (!password) {
      return toast.error("Please Enter Password");
    }



    const signupData = {
      userName,
      email,
      password,
      role: "Study Manager",
      studies,

    };

    await API.signup(signupData)
      .then((resp) => {
        if (resp.status == 200) {
          toast.success(resp.data.message);

          navigate("/study-manager");
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  const getAllStudy = async () => {
    await API.getAllStudy()
      .then((resp) => {
        if (resp.status == 200) {
          setAllStudy(resp.data.data.studies);
          // toast.success(resp.data.message);
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  return (

    <>
      <Sidebar>
        <StudyStyled>
          <div className="Business-Main-Section">
            <div className="Business-Container">
              <div className="Sidebar-Header-Content">
                <div className="Sidebar-Header-Section">
                  <div></div>
                  <div className="Bell-Main-Section">

                    <div className="Avatar-Main-Section">
                      <IoPersonSharp className="Manager-Avatar" />
                      <div className="Avatar-Text-Section">
                        <span className="Avatar-Title">
                          {userData.userName}

                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Call-Center-Title">
                  <span className="call-center">Add Study Manager</span>

                </div>
              </div>
              <div className="Main-Detail-Box">
                <div className="Names-Form">
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title-1">
                      Study List</span>
                    <div className="FormBox-select">
                      <Select
                        className="NameBox-select"
                        value={studies}

                        onChange={setStudies}
                        mode="multiple"
                        suffixIcon={suffix}
                        placeholder="Please select"
                      >
                        {allStudy?.map((item) => (
                          <Option key={item._id} value={item.id}>
                            {item.studyName}
                          </Option>
                        ))}
                      </Select>

                    </div>

                  </div>
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">
                      User Name</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="username"
                        placeholder="Enter User Name"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">

                      Email</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">

                      Password</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {isPasswordVisible ? (
                        <IoEyeOutline className="FormIcon" onClick={togglePasswordVisibility} />
                      ) : (

                        <IoEyeOffOutline className="FormIcon" onClick={togglePasswordVisibility} />
                      )}
                    </div>
                  </div>
                </div>



                <div className="pagination">
                  <button className='Button-1' onClick={() => navigate("/study-list")}>Cancel</button>
                  <button className="Button-2" onClick={(e) => onsignupData(e)}>Submit</button>

                </div>
              </div>
            </div>
          </div>
        </StudyStyled>
      </Sidebar>
    </>
  )
}

export default AddStudy