import { request } from "./apiHandler";


export const base_url = "https://clixs.net/api/";
//export const base_url = "http://localhost:8000/api/";
// export const base_url = "http://192.168.100.6:8000/api/";


export const API = {
    signup: (registerdata) =>
        request.post(base_url + "users/signup", registerdata),
    login: (loginData) => request.post(base_url + "users/signin", loginData),
    signup: (signData) => request.post(base_url + "users/signup", signData),
    forget: (forgetData) =>
        request.post(base_url + "users/forgot-password", forgetData),
    getUpdatePassword: (data) =>
        request.post(base_url + "users/update-password", data),
    createSiteSubmisson: (createSubmissonData) =>
        request.post(base_url + "site-submission/create-site-submission", createSubmissonData),
    getAllSiteSubmissions: (data) =>
        request.post(base_url + "site-submission/get-all-site-submission", data),
    getAllSiteSubmissionvisist: (data) =>
        request.post(base_url + "site-submission/get-all-approved-site-submission-visit", data),
    getAllUserSiteSubmissions: (data) =>
        request.post(base_url + "site-submission/get-user-site-submissions", data),
    updateSubmisson: (data) => request.post(base_url + "site-submission/update-site-submission", data),
    updatePISiteSubmission: (data) => request.post(base_url + "site-submission/update-pi-submission", data),
    deleteSubmisson: (data) => request.post(base_url + "site-submission/delete-site-submission", data),

    createStudy: (createStudyData) =>
        request.post(base_url + "study/create-study", createStudyData),
    getSubmisson: (id) => request.get(base_url + `site-submission/get-site-submission-by-id/${id}`),

    getAllStudy: (data) =>
        request.post(base_url + "study/get-all-study", data),
    getAllStudiesWithoutPagination: (data) =>
        request.get(base_url + "study/get-all-studies", data),
    createReminder: (createReminderData) =>
        request.post(base_url + "remainder/create-remainder", createReminderData),
    getAllReminder: (data) =>
        request.get(base_url + "remainder/get-all-remainder", data),
    deleteReminder: (data) => request.post(base_url + "remainder/delete-remainder", data),
    turnOffRemainder: (id) => request.get(base_url + `remainder/turn-off-remainder/${id}`),
    deleteStudy: (data) => request.post(base_url + "study/delete-study", data),
    getAllStudyManager: (data) =>
        request.get(base_url + "users/get-all-study-managers", data),
    getAllApprovedSiteSubmissions: (data) =>
        request.post(base_url + "site-submission/get-all-approved-site-submissions", data),
    getAllUserSubmissions: (data) =>
        request.post(base_url + "site-submission/get-all-user-site-submissions", data),
    getAllUserSubmissionsWithStudies: (data) =>
        request.post(base_url + "site-submission/get-all-user-studies-site-submissions", data),
    getStudyManagerStudies: (data) => request.post(base_url + "users/get-study-managers-studies", data),
    getAllUnActiveSiteSubmissions: (data) => request.get(base_url + "site-submission/get-all-unactive-sites", data),
    getStudyManagerStudiesWithSerialNumber: (data) =>
        request.post(base_url + "users/get-study-managers-studies-with-sn", data),
    createCda: (CdaData) =>
        request.post(base_url + "cda/create-cda", CdaData),
    getAllSiteCda: (data) =>
        request.post(base_url + "cda/get-all-cda", data),
    updateCDA: (data) => request.post(base_url + "cda/update-cda", data),
    deleteCda: (data) => request.post(base_url + "cda/delete-cda", data),
    getCdaById: (id) => request.get(base_url + `cda/get-cda-by-id/${id}`),
    updateSiteSubmissionVisit: (data) => request.post(base_url + "site-submission/update-site-submission-visits", data),
    getAllApprovedContractOrBudget: (data) => request.post(base_url + "site-submission/get-all-approved-budget-or-contract", data),
    updateContractOrBudget: (data) => request.post(base_url + "site-submission/update-budget-or-contract", data),
    getAllUserApprovedContractOrBudget: (data) => request.post(base_url + "site-submission/get-all-user-approved-site-submissions-visit", data),
    getAllUserApprovedSiteSubmissionVisits: (data) => request.post(base_url + "site-submission/get-all-user-approved-site-submissions-visit", data),
    getAllUserReminder: () => request.get(base_url + "remainder/get-all-user-remainder",),
    getAllUnActiveUsersSiteSubmissions: (data) => request.get(base_url + "site-submission/get-all-user-unactive-sites", data),
    getAllApprovedUsersSiteSubmissions: (data) =>
        request.post(base_url + "site-submission/get-all-user-approved-site-submissions", data),
    getAllUsersReminder: (data) =>
        request.get(base_url + "remainder/get-all-user-remainder", data),
};
