import styled from "styled-components";

export const StudyStyled = styled.div`
display: flex;
width: 100%;
background-color: #F3F3F9;
.Business-Main-Section {
    width: 100%;
    display: flex;
    flex-direction: column;

    height: 100vh;
    overflow: auto;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  .Business-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Sidebar-Header-Section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #f1f1f7;
    padding-bottom: 10px;
  }
  .icon {
    cursor: pointer;
    font-size: 20px;
    padding: 5px 10px;
}

.icon.success {
    color: green;
}

.icon.error {
    color: red;
}

.icon.highlight {
    background-color: lightgreen;
    border-radius: 50%;
    height: 22px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .Sidebar-Header-Content {
    width: 100%;
    display: flex;

    flex-direction: column;
  }
  .Sidebar-Search-Box-Section {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .kyc-search {
    width: 200px;
    height: 29px;
    font-size: 13px;
    border: none;
    outline: none;
    color: black;
    background-color: #edeaf4;
  }
  .kyc-search-box {
    display: flex;
    align-items: center;
    background-color: #edeaf4;
    padding: 4px;
    border-radius: 4px;
    margin-left: 20px;
    cursor: pointer;
  }
  .kyc-icon {
    width: 20px;
    height: 18px;
    color: #878a99;
  }

  .Listing-Icon {
    width: 47px;
    height: 30px;
    color: #878a99;
  }
  .Avatar-Main-Section {
    display: flex;
    background-color: #f3f3f9;
    width: 165px;
    height: 64px;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .Avatar-Title {
    font-size: 14px;
    font-weight: 400;

    color: #2d2f39;
  }
  .Avatar-Text {
    font-size: 15px;
    font-weight: 500;
    color: #2d2f39;
  }
  .Manager-Avatar {
    height: 35px;
    width: 24px;
  }
  .Avatar-Text-Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Bell-Main-Section {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 10px;
  }
  .Bell-Icon {
    color: #495057;
    width: 30px;
    height: 25px;
  }
  .Call-Center-Title {
 
 
 color: #2d2f39;
 background-color: #ffffff;
 padding-left: 20px;

 padding-bottom: 16px;
 display: flex;
 justify-content: space-between;
}
.call-center{
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 16px;
}
.Title-Section{
    display: flex;
    align-items: center;

}
.Study-Text{
    font-size: 16px;

}
.Study-icon{
    height: 20px;
    width: 22px;
}
.Study-Title{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
   color: #878A99;
   margin-right: 10px;
}
  .Main-Detail-Box{
    display: flex;
    /* justify-content: center; */
    background-color: white;
    height: 718px;
    width: 99%;
    flex-direction: column;
    margin-top: 10px;
    border-radius: 5px;
  }
.Names-Form{
    display: flex;
justify-content: center;
gap: 10px;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    flex-direction: column;
  
}
.Names-Form-1 {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-top: 10px;

}
.Names-Form-Last{
    display: flex;
justify-content: center;
gap: 10px;
    align-items: center;
 
   

    flex-direction: column;
}

.FormBox-select{

cursor: pointer;
  }
  
  .NameBox-select {
    width: 520px;
    height: 70px;
    overflow: auto;
    font-size: 17px;
    border: none;
    padding-left: 10px;
    outline: none;
    color: black;
  }
.Form-Box-Text-Bottom{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 25px;
}
.Form-cv {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 25px;
    flex-direction: column;
    gap: 10px;
}
.RemoveFile{
  display: flex;
  align-items: center;
  gap: 5px;
  color: red;
cursor: pointer;
    text-decoration: underline;
}

.NameBox-cv {
    width: 480px;
    height: 37px;
    font-size: 17px;
    border: none;
    outline: none;
    color: black;
  }
 .NameBox-1 {
    width: 140px;
    height: 37px;
    font-size: 17px;
    border: none;
    padding-left: 10px;
    outline: none;
    color: black;
  }
  .FormBox-1 {
    border: 2px #e6ebf2 solid;
    padding: 4px;
    border-radius: 9px;
    cursor: pointer;
    width: 150px;
  }
  .NameBox {
    width: 480px;
    height: 37px;
    font-size: 17px;
    border: none;
    padding-left: 10px;
    outline: none;
    color: black;
  }
  .FormBox {
    border: 2px #e6ebf2 solid;
    padding: 4px;
    border-radius: 9px;
    cursor: pointer;
    width: 490px;
  }
.Card-Title{
    font-size: 16px;
    color: #878A99;
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 10px;
    flex-direction: column;
    width: 100%;
}
.FormBox-Comment {
    border: 2px #e6ebf2 solid;
    padding: 4px;
    border-radius: 9px;
    cursor: pointer;
    width: 490px;
    height: 179px;
}
  .NameBox-Comment {
    width: 470px;
   
    font-size: 17px;
    height: 161px;
    border: none;
    padding-left: 10px;
    outline: none;
    color: black;
  }

  .Button-2 {
    width: 487px;
    cursor: pointer;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #71C495;
    font-size: 15px;
    font-weight: 500;
    color: white;
  }
  .Button-1{
    width: 487px;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    font-size: 15px;
    font-weight: 500;
   color: black;
  }
  .Button-1:hover{
background-color: #71C495;
color: white
;
  }
  .Button-2:hover{
background-color: #71C495;
}
.status-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px; /* Adjust width as needed */
}

.icon {
  font-size: 24px; /* Adjust icon size as needed */
  padding: 5px;
}

.success {
  color: blue;
  cursor: pointer;
}

.pending {
  color: grey;
}

.error {
  cursor: pointer;
  color: red;
}
 .Icons-Section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
    width: 492px;
}
.Check-Box-Section{
  display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
    width: 492px;
}
.Title{
  font-size: 18px; 
  font-weight: 500;
}

 .Checkbox {
    width: 21px;
    height: 18px;
}
.Header-Form{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 19px;
    gap: 35px;
    flex-direction: column;
}
.Image{
  width: 300px;
}
.Header-text{
font-size: 27px;
margin-bottom: 15px;
font-weight: 500;
}

`;