import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar-2/SideBar";
import { ReminderStyled, StudyStyled } from "./style"
import { IoPersonSharp } from "react-icons/io5";
import { Await, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { RiInboxArchiveLine } from "react-icons/ri";
import { API } from "../../../api/api";
import { toast } from "react-toastify";
import { FcAlarmClock } from "react-icons/fc";

function StudyList() {
  function formatDate(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Format the date as per your requirements
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",

    });

    return formattedDate;
  }
  let { id } = useParams();
  const [studyManagerStudies, setStudyManagerStudies] = useState([]);
  const navigate = useNavigate();
  const [allStudy, setAllStudy] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reminders, setReminders] = useState([]);
  const userData = JSON.parse(localStorage.getItem("user"));
  console.log(userData)

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("user"));
    // const userId = user._id;
    getAllReminder();
  }, []);
  function ReminderComponent({ reminder }) {
    return (
      <ReminderStyled>
        <div className="reminder">
          <div className="reminder-content">
            <div className="Title-Section">

              <p className="Reminder-Title">
                {reminder.title}</p>

            </div>
            <div className="Title-Section">
              <FcAlarmClock className="Icon" />
              <p className="Reminder-Title">
                {formatDate(reminder.dateAndTime)}
              </p>

            </div>

            <span className="close" onClick={() => closeReminder(reminder._id)}>&times;</span>
          </div>
        </div>
      </ReminderStyled>
    );
  }


  const getAllReminder = async () => {
    try {
      const response = await API.getAllUserReminder({


      });
      const responseData = response.data;

      if (responseData && responseData.success) {
        setReminders(responseData.data);

      } else {
        // toast.error("Failed to fetch companies");
      }
    } catch (error) {
      // toast.error("Error fetching submissons: " + error.message);
    }
  };
  const closeReminder = async (id) => {
    // socket.emit("toggleReminder", reminderId, true);

    // preventDefault();
    await API.turnOffRemainder(
      id,
    )
      .then((resp) => {
        // console.log(resp);
        if (resp.status == 200) {
          toast.success(resp.data.message);
          // console.log(resp.data.data);
          setReminders(reminders.filter(reminder => reminder._id !== id));
        }
      })
      .catch((e) => toast.error(e.response.data.message));


  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getStudyManagerStudies();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage]);


  useEffect(() => {
    getStudyManagerStudies(currentPage);
  }, [currentPage]);

  const getStudyManagerStudies = async () => {
    try {
      const userId = userData._id
      console.log(userId)
      const response = await API.getStudyManagerStudies({
        id: userId,
        page: currentPage,
        pageSize: 1

      });
      const responseData = response.data;

      if (responseData && responseData.success) {
        setStudyManagerStudies(responseData.data);

      } else {
        // toast.error("Failed to fetch companies");
      }
    } catch (error) {
      // toast.error("Error fetching submissons: " + error.message);
    }
  };


  return (

    <>
      <Sidebar>
        <StudyStyled>
          <div>
            {reminders?.map((reminder) => (
              <ReminderComponent
                key={reminder._id}
                reminder={reminder}
                onClose={closeReminder}
              />
            ))}
          </div>
          <div className="Business-Main-Section">
            <div className="Business-Container">
              <div className="Sidebar-Header-Content">
                <div className="Sidebar-Header-Section">
                  <div></div>
                  <div className="Bell-Main-Section">

                    <div className="Avatar-Main-Section">
                      <IoPersonSharp className="Manager-Avatar" />
                      <div className="Avatar-Text-Section">
                        <span className="Avatar-Title">
                          {userData.userName}

                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Call-Center-Title">
                  <span className="call-center">Study List</span>

                </div>
              </div>
              <div className="Main-Detail-Box">
                <div className='Main-Content'>
                  <span className="Contact-Title"></span>
                  {/* <button className='Add-File-button' onClick={() => navigate("/create-user-study")}> + Add New Study</button> */}
                </div>


                <div className='Card-Section' >
                  {studyManagerStudies?.map((item, index) => (
                    <div className='Card-Main-Section' onClick={() => navigate(`/user-submissons/${item.studyName}`)}>
                      <div className='Container' key={index}>
                        <div className='Title-Main'> <span className="Title">{item.studyName}</span>  <RiInboxArchiveLine className='Card-Icon' /></div>

                        <span className='Card-Title'>{item.studyID}</span>
                        <span className='Card-Title'>{formatDate(item?.createdAt)}</span>
                      </div>
                    </div>
                  ))}

                </div>
                <div className="pagination-container">
                  <span className="pagination-text">

                  </span>
                  <div className="pagination-buttons">
                    <button
                      className="page-button"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {Array.from(
                      Array(allStudy?.pagination?.totalPages).keys()
                    ).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={`page-button${currentPage === pageNumber + 1 ? " active" : ""
                          }`}
                        onClick={() => setCurrentPage(pageNumber + 1)}
                      >
                        {pageNumber + 1}
                      </button>
                    ))}
                    <button
                      className="page-button"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={
                        currentPage === allStudy?.pagination?.totalPages
                      }
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StudyStyled>
      </Sidebar>
    </>
  )
}
export default StudyList