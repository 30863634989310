import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar/SideBar"
import { ReminderStyled, StudyStyled } from "./style"
import { IoPersonSharp } from "react-icons/io5";
import { Await, useNavigate } from "react-router-dom";
import { FcAlarmClock } from "react-icons/fc";
import { RiInboxArchiveLine } from "react-icons/ri";
import { API } from "../../../api/api";
import { toast } from "react-toastify";

function StudyList() {
  function formatDate(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Format the date as per your requirements
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",

    });

    return formattedDate;
  }
  const navigate = useNavigate();
  const [allStudy, setAllStudy] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reminders, setReminders] = useState([]);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  function formatDate1(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Format the date as per your requirements
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, // If you want 12-hour format
    });

    return formattedDate;
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user._id;
    getAllReminder();
  }, []);
  function ReminderComponent({ reminder }) {
    return (
      <ReminderStyled>
        <div className="reminder">
          <div className="reminder-content">
            <div className="Title-Section">

              <p className="Reminder-Title">
                {reminder.title}</p>

            </div>
            <div className="Title-Section">
              <FcAlarmClock className="Icon" />
              <p className="Reminder-Title">
                {formatDate1(reminder.dateAndTime)}
              </p>

            </div>

            <span className="close" onClick={() => closeReminder(reminder._id)}>&times;</span>
          </div>
        </div>
      </ReminderStyled>
    );
  }


  const getAllReminder = async () => {
    try {
      const response = await API.getAllUserReminder({


      });
      const responseData = response.data;

      if (responseData && responseData.success) {
        setReminders(responseData.data);

      } else {
        // toast.error("Failed to fetch companies");
      }
    } catch (error) {
      // toast.error("Error fetching submissons: " + error.message);
    }
  };
  const closeReminder = async (id) => {
    // socket.emit("toggleReminder", reminderId, true);

    // preventDefault();
    await API.turnOffRemainder(
      id,
    )
      .then((resp) => {
        // console.log(resp);
        if (resp.status == 200) {
          toast.success(resp.data.message);
          // console.log(resp.data.data);
          setReminders(reminders.filter(reminder => reminder._id !== id));
        }
      })
      .catch((e) => toast.error(e.response.data.message));


  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllStudy();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage]);


  useEffect(() => {
    getAllStudy(currentPage);
  }, [currentPage]);


  const getAllStudy = async () => {
    try {
      const response = await API.getAllStudy({
        page: currentPage,


      });
      const responseData = response.data;

      if (responseData && responseData.success) {
        setAllStudy(responseData.data.studies);

      } else {
        // toast.error("Failed to fetch companies");
      }
    } catch (error) {
      // toast.error("Error fetching submissons: " + error.message);
    }
  };



  const handleDelete = async (e, id) => {
    e.preventDefault();
    await API.deleteStudy({
      id: id,
    })
      .then((resp) => {
        // console.log(resp);
        if (resp.status == 200) {
          toast.success(resp.data.message);
          // console.log(resp.data.data);
          getAllStudy();
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  return (

    <>
      <Sidebar>
        <StudyStyled>
          <div>
            {reminders?.map((reminder) => (
              <ReminderComponent
                key={reminder._id}
                reminder={reminder}
                onClose={closeReminder}
              />
            ))}
          </div>
          <div className="Business-Main-Section">
            <div className="Business-Container">
              <div className="Sidebar-Header-Content">
                <div className="Sidebar-Header-Section">
                  <div></div>
                  <div className="Bell-Main-Section">

                    <div className="Avatar-Main-Section">
                      <IoPersonSharp className="Manager-Avatar" />
                      <div className="Avatar-Text-Section">
                        <span className="Avatar-Title">
                          {userData.userName}

                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Call-Center-Title">
                  <span className="call-center">Study List</span>

                </div>
              </div>
              <div className="Main-Detail-Box">
                <div className='Main-Content'>
                  <span className="Contact-Title"></span>
                  <button className='Add-File-button' onClick={() => navigate("/add-study")}> + Add New Study</button>
                </div>

                <div className='Card-Section'>
                  {allStudy?.map((item, index) => (
                    <div className='Card-Main-Section' key={index}>
                      <div className='Container'>
                        <div className='Title-Main'>
                          <span
                            className={`Title ${expandedIndex === index ? 'full-text' : ''}`}
                            onClick={() => handleToggle(index)}
                          >
                            {item.studyName || 'No Study Name'}
                          </span>
                          <RiInboxArchiveLine
                            className='Card-Icon'
                            onClick={(e) => handleDelete(e, item._id)}
                          />
                        </div>
                        <span
                          className={`Card-Title ${expandedIndex === index ? 'full-text' : ''}`}
                          onClick={() => handleToggle(index)}
                        >
                          {item.studyID || 'No Study ID'}
                        </span>
                        <span
                          className={`Card-Title ${expandedIndex === index ? 'full-text' : ''}`}
                          onClick={() => handleToggle(index)}
                        >
                          {formatDate(item?.createdAt) || 'No Date'}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>


                <div className="pagination-container">
                  <span className="pagination-text">

                  </span>
                  <div className="pagination-buttons">
                    <button
                      className="page-button"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {Array.from(
                      Array(allStudy?.pagination?.totalPages).keys()
                    ).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={`page-button${currentPage === pageNumber + 1 ? " active" : ""
                          }`}
                        onClick={() => setCurrentPage(pageNumber + 1)}
                      >
                        {pageNumber + 1}
                      </button>
                    ))}
                    <button
                      className="page-button"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={
                        currentPage === allStudy?.pagination?.totalPages
                      }
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StudyStyled>
      </Sidebar>
    </>
  )
}
export default StudyList