import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from './pages/homepage';
import StudyManager from "./Component/Study-Manager/StudyManager";
import StudyList from "./Component/Super-Admin-Pages/Study-List/StudyList";
import AddStudy from "./Component/Create-Update-Forms/Create-Forms/Add-Study/AddStudy";
import Reminder from "./Component/Super-Admin-Pages/Reminder/Reminder";
import PiSubmisson from "./Component/Super-Admin-Pages/Pi-Submisson/PiSubmisson";
import AddReminder from "./Component/Create-Update-Forms/Create-Forms/Add-Reminder/AddReminder";
import SSV from "./Component/Super-Admin-Pages/SSV/SSV";
import SiteSubmisson from "./Component/Super-Admin-Pages/Site-Submisson/SiteSubmisson";
import AddSiteSubmisson from "./Component/Create-Update-Forms/Create-Forms/Add-Site-Submisson/AddSiteSubmisson";
import UpdateSiteSubmisson from "./Component/Create-Update-Forms/Update-Forms/Update-Site-Submisson/UpdateSiteSubmisson";
import AddStudyManager from "./Component/Create-Update-Forms/Create-Forms/AddStudyManager/AddStudy"
import UserSubmisson from "./Component/User-Submisson/UserSubmisson";
import CV from "./Component/Super-Admin-Pages/CV-Table/Index"
import StudySubmisson from "./Component/Super-Admin-Pages/study-submissons/UserSubmisson"
import UserStudyManager from "./Component/User-Study-manager/StudyList";
import UpdatePi from "./Component/Create-Update-Forms/Update-Forms/Update-Pi/UpdateSiteSubmisson";
import UnactiveSubmissions from "./Component/Super-Admin-Pages/Un-Active-Site-Submisson/SiteSubmisson"
import InActiveUpdate from "./Component/Create-Update-Forms/Update-Forms/update-in-active-submisson/UpdateSiteSubmisson"
import UserPiSubmisson from "./Component/User-Pages/Pi-Submisson/PiSubmisson";
import UserReminder from "./Component/User-Pages/Reminder/Reminder";
import UserInActive from "./Component/User-Pages/Un-Active-Site-Submisson/SiteSubmisson";
import UserInvetory from "./Component/User-Pages/CV-Table/Index"
import StudiesManager from "./Component/User-Pages/User-Study-manager/StudyList"
import CreateUserStudy from "./Component/Users-Create-Update-Forms/Create-Forms/Add-Study/AddStudy"
import UpdateInactive from "./Component/Users-Create-Update-Forms/Update-Forms/update-in-active-submisson/UpdateSiteSubmisson";
import UpdateUserPi from "./Component/Users-Create-Update-Forms/Update-Forms/Update-Pi/UpdateSiteSubmisson";
import UserSiteSubmisson from "./Component/User-Pages/study-submissons/UserSubmisson"
import ManagerStudiesforadmin from "./Component/Super-Admin-Pages/User-Study-manager/StudyList"
import UpdateUserSiteSubmisson from "./Component/Create-Update-Forms/Update-Forms-For-Users/Update-Site-Submisson/UpdateSiteSubmisson"
import AddUserReminder from "./Component/Users-Create-Update-Forms/Create-Forms/Add-Reminder/AddReminder"
import AddCda from "./Component/Create-Update-Forms/Create-Forms/Add-Cda/AddCda"
import Cda from "./Component/Super-Admin-Pages/Site-cda-repository/Site.js"
import UpdateCDA from "./Component/Create-Update-Forms/Update-Forms/Update-CDA/UpdateSiteSubmisson.js"
import UserCda from "./Component/User-Pages/Site-cda-repository/Site.js"
import UpdateUserCda from "./Component/Users-Create-Update-Forms/Update-Forms/Update-CDA/UpdateSiteSubmisson.js"
import AddUserCda from "./Component/Users-Create-Update-Forms/Create-Forms/Add-Cda/AddCda.js"
import Setting from "./Component/Setting/User.js"
import UserSetting from "./Component/User-Pages/User-Setting/User.js"
import BudgetContract from "./Component/Super-Admin-Pages/Budget-Contract/Contract.js"
import UpdateSiteSelection from "./Component/Create-Update-Forms/Update-Forms/Update-Site-Selection/UpdateSiteSubmisson.js"
import UpdateContractBudget from "./Component/Create-Update-Forms/Update-Forms/Update-Contract-Budget/UpdateSiteSubmisson.js"
import Snpa from "./Component/Super-Admin-Pages/Snpa-respositories/Snpa.js";
import UpdateSnpa from "./Component/Create-Update-Forms/Update-Forms/Update-Snpa/UpdateSiteSubmisson.js"
import AddCdaSponsor from "./Component/Create-Update-Forms/Create-Forms/Add-Cda-Sponsor/AddCda.js"
import CdaSponsor from "./Component/Super-Admin-Pages/Sponsor-cda-repository/Sponser.js"
import UpdateCDASponsor from "./Component/Create-Update-Forms/Update-Forms/Update-CDA-Sponsor/UpdateSiteSubmisson.js"
import UserCdaSponsor from "./Component/User-Pages/Site-Sponsor-repository/Site.js"
import UserCdaSponsorUpdate from "./Component/Users-Create-Update-Forms/Update-Forms/Update-CDA -Sponsor/UpdateSiteSubmisson.js"
import CreateUserCdaSponsor from "./Component/Users-Create-Update-Forms/Create-Forms/Add-Cda -Sponsor/AddCda.js"
import UserSnpa from "./Component/User-Pages/Snpa-respositories/Snpa.js"
import UpdateUserSnpa from "./Component/Users-Create-Update-Forms/Update-Forms/Update-Snpa/UpdateSiteSubmisson.js"
import UserBudgetContract from "./Component/User-Pages/Budget-Contract/Contract.js"
import UpdateUserBudgetContract from "./Component/Users-Create-Update-Forms/Update-Forms/Update-Contract-Budget/UpdateSiteSubmisson.js"
import UserSsv from "./Component/User-Pages/SSV/SSV.js";
import UpdateUserSsv from "./Component/Users-Create-Update-Forms/Update-Forms/Update-Site-Selection/UpdateSiteSubmisson.js";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/study-manager" element={<StudyManager />} />
        <Route path="/study-list" element={<StudyList />} />
        <Route path="/add-study" element={<AddStudy />} />
        <Route path="/reminder" element={<Reminder />} />
        <Route path="/add-reminder" element={<AddReminder />} />
        <Route path="/ssv" element={<SSV />} />
        <Route path="/site-submisson" element={<SiteSubmisson />} />
        <Route path="/join-a-trail/:sn" element={<AddSiteSubmisson />} />
        <Route path="/add-study-manager" element={<AddStudyManager />} />
        <Route path="/user-submisson/:id" element={<UserSubmisson />} />
        <Route path="/site-submisson-update/:id" element={<UpdateSiteSubmisson />} />
        <Route path="/pi-submisson" element={<PiSubmisson />} />
        <Route path="/study-submisson/:id/:studyId" element={<StudySubmisson />} />
        <Route path="/manager-studies/:id" element={<UserStudyManager />} />
        <Route path="/user-manager-studies" element={<StudiesManager />} />
        <Route path="/cv" element={<CV />} />
        <Route path="/un-active-submissions" element={<UnactiveSubmissions />} />
        <Route path="/pi-update-form/:id" element={<UpdatePi />} />
        <Route path="/in-active-update-form/:id" element={<InActiveUpdate />} />
        <Route path="/manager-studies-for-admin/:id" element={<ManagerStudiesforadmin />} />
        <Route path="/add-cda" element={<AddCda />} />
        <Route path="/cda" element={<Cda />} />
        <Route path="/update-cda/:id" element={<UpdateCDA />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/budget-contract" element={<BudgetContract />} />
        <Route path="/update-contract-budget/:id" element={<UpdateContractBudget />} />
        <Route path="/update-site-selection-visit/:id" element={<UpdateSiteSelection />} />
        <Route path="/snpa" element={<Snpa />} />
        <Route path="/update-snpa/:id" element={<UpdateSnpa />} />
        <Route path="/add-cda-sponsor" element={<AddCdaSponsor />} />
        <Route path="/cda-sponsor" element={<CdaSponsor />} />
        <Route path="/update-cda-sponsor/:id" element={<UpdateCDASponsor />} />
        <Route path="/update-user-ssv/:id" element={<UpdateUserSsv />} />
        <Route path="/user-ssv" element={<UserSsv />} />
        <Route path="/update-user-budget-contract/:id" element={<UpdateUserBudgetContract />} />
        <Route path="/user-budget-contract" element={<UserBudgetContract />} />
        <Route path="/user-snpa" element={<UserSnpa />} />
        <Route path="/update-user-snpa/:id" element={<UpdateUserSnpa />} />
        <Route path="/user-cda-sponsor" element={<UserCdaSponsor />} />
        <Route path="/user-update-cda-sponsor/:id" element={<UserCdaSponsorUpdate />} />
        <Route path="/create-user-cda-sponsor" element={<CreateUserCdaSponsor />} />
        <Route path="/user-cda" element={<UserCda />} />
        <Route path="/user-budget-contract" element={<BudgetContract />} />
        <Route path="/user-selection-site" element={<UserCda />} />
        <Route path="/user-pi-submisson" element={<UserPiSubmisson />} />
        <Route path="/user-reminder" element={<UserReminder />} />
        <Route path="/user-in-active-submissons" element={<UserInActive />} />
        <Route path="/user-invetory" element={<UserInvetory />} />
        <Route path="/user-managers-studies/:id" element={<StudiesManager />} />
        <Route path="/user-submissons/:id" element={<UserSiteSubmisson />} />
        <Route path="/user-setting" element={<UserSetting />} />
        <Route path="/create-user-reminder" element={<AddUserReminder />} />
        <Route path="/create-user-cda" element={<AddUserCda />} />
        <Route path="/user-site-submisson-update/:id" element={<UpdateUserSiteSubmisson />} />
        <Route path="/create-user-study" element={<CreateUserStudy />} />
        <Route path="/user-update-inactive-submisson/:id" element={<UpdateInactive />} />
        <Route path="/user-update-Pi/:id" element={<UpdateUserPi />} />
        <Route path="/user-update-cda/:id" element={<UpdateUserCda />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
