import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoPersonSharp } from "react-icons/io5";
import Sidebar from "../../Sidebar-2/SideBar";
import { ReminderStyled, Partnerstyled } from "./style";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { API } from "../../../api/api";
import { toast } from "react-toastify";
import { FcAlarmClock } from "react-icons/fc";

function Index() {
    const navigate = useNavigate();
    function formatDate1(dateString) {
        // Create a new Date object from the dateString
        const date = new Date(dateString);

        // Format the date as per your requirements
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true, // If you want 12-hour format
        });

        return formattedDate;
    }
    const userData = JSON.parse(localStorage.getItem("user"));
    const [companies, setCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [allsiteSubmisson, setAllSiteSubmissons] = useState([]);
    const [reminders, setReminders] = useState([]);
    const handleDownload = () => {

        const data = allsiteSubmisson.map((item, index) => ({
            "Sl.No": index + 1,

            Address: item.address,
            "Email": item.piEmail,


            UpdatedCV: item.updatedCV,
            MedicalCertificate: item.medicalCertificate,
        }));

        // Convert data to Excel workbook
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "CV_Submissions");

        // Generate Excel file and initiate download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const file = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(file, "CV_Submissions.xlsx");
    };


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const userId = user._id;
        getAllReminder();
    }, []);
    function ReminderComponent({ reminder }) {
        return (
            <ReminderStyled>
                <div className="reminder">
                    <div className="reminder-content">
                        <div className="Title-Section">

                            <p className="Reminder-Title">
                                {reminder.title}</p>

                        </div>
                        <div className="Title-Section">
                            <FcAlarmClock className="Icon" />
                            <p className="Reminder-Title">
                                {formatDate1(reminder.dateAndTime)}
                            </p>

                        </div>

                        <span className="close" onClick={() => closeReminder(reminder._id)}>&times;</span>
                    </div>
                </div>
            </ReminderStyled>
        );
    }


    const getAllReminder = async () => {
        try {
            const response = await API.getAllUserReminder({


            });
            const responseData = response.data;

            if (responseData && responseData.success) {
                setReminders(responseData.data);

            } else {
                // toast.error("Failed to fetch companies");
            }
        } catch (error) {
            // toast.error("Error fetching submissons: " + error.message);
        }
    };
    const closeReminder = async (id) => {
        // socket.emit("toggleReminder", reminderId, true);

        // preventDefault();
        await API.turnOffRemainder(
            id,
        )
            .then((resp) => {
                // console.log(resp);
                if (resp.status == 200) {
                    toast.success(resp.data.message);
                    // console.log(resp.data.data);
                    setReminders(reminders.filter(reminder => reminder._id !== id));
                }
            })
            .catch((e) => toast.error(e.response.data.message));


    };
    useEffect(() => {
        getAllApprovedSiteSubmissions();
    }, []);

    const getAllApprovedSiteSubmissions = async () => {
        try {
            const resp = await API.getAllApprovedUsersSiteSubmissions();
            if (resp.status === 200) {
                setAllSiteSubmissons(resp.data.data.siteSubmissions);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "Error fetching data");
        }
    };
    function shortenText(name) {
        console.log(name)
        if (name?.length <= 10) {
            return name;
        }
        return name?.slice(0, 10) + '...';
    }
    return (
        <>
            <Sidebar>
                <Partnerstyled>
                    <div>
                        {reminders?.map((reminder) => (
                            <ReminderComponent
                                key={reminder._id}
                                reminder={reminder}
                                onClose={closeReminder}
                            />
                        ))}
                    </div>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div className="Sidebar-Search-Box-Section">

                                    </div>
                                    <div className="Bell-Main-Section">

                                        <div className="Avatar-Main-Section">
                                            <IoPersonSharp className="Manager-Avatar" />
                                            <div className="Avatar-Text-Section">
                                                <span className="Avatar-Title">
                                                    {userData.userName}

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Call-Center-Title">
                                    <span className="call-center">
                                        PI CV & ML</span>
                                </div>
                            </div>
                            <div className="Parter-Main-Section">
                                <div className="Partner-Container">
                                    <div className="Partner-Form-Header">
                                        <div className="Header-Text">
                                            <span>
                                            </span>
                                        </div>
                                        <div className="Header-Button-Section">

                                            <button className="Comment-Button"
                                                onClick={handleDownload}
                                            >
                                                Download </button>

                                        </div>
                                    </div>
                                    <div className="Name-Filter-Box1">
                                        <div className="kyc-search-box1">


                                        </div>
                                    </div>
                                    <div className="Table-Scrolling">
                                        <div className="Table-Section">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Sl.No</th>
                                                        <th>PI Name</th>
                                                        <th>Site Name</th>
                                                        <th>Email</th>
                                                        <th>Address</th>
                                                        <th>CV</th>
                                                        <th>Medical License</th>



                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allsiteSubmisson?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.piName}</td>
                                                            <td>{item.siteName}</td>
                                                            <td >{item.piEmail}</td>
                                                            <td>{item.address}</td>

                                                            <td>
                                                                <a class="Comment-Button" href={item.updatedCV} download="CV.pdf">{shortenText(item.updatedCVName, 5)}</a>
                                                            </td>
                                                            <td>
                                                                <a class="Comment-Button" href={item.medicalCertificate} download="MediaclCertificate.pdf"> {shortenText(item.medicalCertificateName, 5)}</a>

                                                            </td>


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="pagination-container">
                                    <span className="pagination-text">

                                    </span>
                                    <div className="pagination-buttons">
                                        <button
                                            className="page-button"
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        {Array.from(
                                            Array(allsiteSubmisson?.pagination?.totalPages).keys()
                                        ).map((pageNumber) => (
                                            <button
                                                key={pageNumber}
                                                className={`page-button${currentPage === pageNumber + 1 ? " active" : ""
                                                    }`}
                                                onClick={() => setCurrentPage(pageNumber + 1)}
                                            >
                                                {pageNumber + 1}
                                            </button>
                                        ))}
                                        <button
                                            className="page-button"
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            disabled={
                                                currentPage === allsiteSubmisson?.pagination?.totalPages
                                            }
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Partnerstyled>
            </Sidebar>
        </>
    );
}

export default Index