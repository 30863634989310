import React, { useEffect, useState } from "react";
// import Sidebar from "../Sidebar/SideBar"
import { useParams } from 'react-router-dom';
import { ModalStyled, StudyStyled } from "./style"
import Logo from "../../../../images/login-logo.png"
import { useNavigate } from "react-router-dom";
import { API } from "../../../../api/api";
import { toast } from "react-toastify";
// import { Select } from 'antd';
// import { DownOutlined } from '@ant-design/icons';
import { FaTrashAlt } from 'react-icons/fa';


function AddSiteSubmisson() {
    // const { Option } = Select;
    const navigate = useNavigate();

    const [studyName, setStudyName] = useState(null);
    const [studiesWithSerialNumber, setStudiesWithSerialNumber] = useState([]);
    const [siteName, setSiteName] = useState(null);
    const [address, setAddress] = useState(null);
    const [piAddress, setPiAddress] = useState('');
    const [piEmail, setPiEmail] = useState(null);
    const [piName, setPiName] = useState(null);
    const user = localStorage.getItem("user")
    const [city, setCity] = useState(null);
    const [zip, setZip] = useState(null);
    const [specialty, setSpecialty] = useState(null);
    const [crcName, setCrcName] = useState(null);
    const [crcEmail, setCrcEmail] = useState(null);
    const [additionalComment, setAdditionalComment] = useState(null);
    const [crcPhone, setCrcPhone] = useState(null);
    const [state, setState] = useState(null);
    const [file, setFile] = useState(null);
    const [certificate, setCertificate] = useState(null)
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const [charCount1, setCharCount1] = useState(0);
    const [charCount2, setCharCount2] = useState(0);
    const [charCount3, setCharCount3] = useState(0);
    const [charCount4, setCharCount4] = useState(0);
    const [charCount5, setCharCount5] = useState(0);
    const [charCount6, setCharCount6] = useState(0);

    const maxChars = 200;

    const handleClose = () => {
        setIsModalOpen(false);
    };
    const Modal = () => {
        return (
            <ModalStyled>
                <div className="modal-overlay" onClick={() => handleClose()}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className='Closing-Section'>
                        </div>
                        <div class="spinnerContainer">
                            <div class="spinner"></div>
                            <div class="loader">
                                {/* <p className="Loading-Title">loading</p> */}
                                <div class="words">
                                    <span class="word">Cliniverse</span>
                                    <span class="word">Cliniverse</span>
                                    <span class="word">Cliniverse</span>
                                    <span class="word">Cliniverse</span>
                                    <span class="word">Cliniverse</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalStyled>
        );
    };



    let { sn } = useParams();


    const handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };
    const handleInput1Change = (e) => {
        const value = e.target.value;
        const filteredValue = value.replace(/[^a-zA-Z]/g, '');
        setCity(filteredValue);
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        // Remove any non-alphabetic characters
        const filteredValue = value.replace(/[^a-zA-Z]/g, '');
        setState(filteredValue);
    };
    const getInputClass = (inputValue) => {
        return formSubmitted && !inputValue ? 'input-error' : '';
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleRemoveFile = () => {
        setFile(null);
        document.getElementById('fileInput').value = '';
    };
    const handleCommentChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setAdditionalComment(value);
            setCharCount(value.length);
        }
    };
    const handleStudyNameChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setStudyName(value);
            setCharCount1(value.length);
        }
    };
    const handleSiteNameChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setSiteName(value);
            setCharCount2(value.length);
        }
    };
    const handleAddressChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setAddress(value);
            setCharCount3(value.length);
        }
    };
    const handlePiNameChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setPiName(value);
            setCharCount4(value.length);
        }
    };
    const handleSpecialtyChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setSpecialty(value);
            setCharCount5(value.length);
        }
    };
    const handleCrcNameChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setCrcName(value);
            setCharCount6(value.length);
        }
    };
    const oncreateSiteSubmisson = async (e) => {
        // e.preventDefault();
        setFormSubmitted(true);
        setIsModalOpen(true);
        if (!studyName) {
            toast.error("Please Enter Study Name");
            return setIsModalOpen(false);
        }
        if (studyName.length > 200) {
            toast.error(`Study Name should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!siteName) {

            toast.error("Please Enter Site Name");
            return setIsModalOpen(false);
        }
        if (siteName.length > 200) {
            toast.error(`Site Name should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!address) {

            toast.error("Please Enter Address");
            return setIsModalOpen(false);
        }
        if (address.length > 200) {
            toast.error(`Address should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!state) {

            toast.error("Please Enter State");
            return setIsModalOpen(false);
        }
        if (state.length > 200) {
            toast.error(`State should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!city) {

            toast.error("Please Enter City");
            return setIsModalOpen(false);
        }
        if (city.length > 200) {
            toast.error(`City should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!zip) {

            toast.error("Please Enter Zip");
            return setIsModalOpen(false);
        }
        if (zip.length > 7) {

            toast.error(`Zip should not exceed 7 Numbers`);
            return setIsModalOpen(false);
        }
        if (!piName) {

            toast.error("Please Enter PI Name");
            return setIsModalOpen(false);
        }
        if (piName.length > 200) {
            toast.error(`PI Name should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!piEmail) {

            toast.error("Please Enter PI Email");
            return setIsModalOpen(false);
        }
        if (piEmail.length > 200) {
            toast.error(`PI Email should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!specialty) {

            toast.error("Please Enter Specialty");
            return setIsModalOpen(false);
        }
        if (specialty.length > 200) {
            toast.error(`Specialty should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!crcName) {
            toast.error("Please Enter CRC Name");
            return setIsModalOpen(false);
        }
        if (crcName.length > 200) {
            toast.error(`CRC Name should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!crcEmail) {
            toast.error("Please Enter CRC Email");
            return setIsModalOpen(false);
        }
        if (crcEmail.length > 200) {
            toast.error(`CRC Email should not exceed 200 words`);
            return setIsModalOpen(false);
        }
        if (!crcPhone) {
            toast.error("Please Enter CRC Phone");
            return setIsModalOpen(false);
        }

        if (!file) {
            toast.error("Please Enter CV");
            return setIsModalOpen(false);
        }

        const formData = new FormData()
        // formData.append('userId', user._id);
        formData.append('studyName', studyName);
        formData.append('siteName', siteName);
        formData.append('address', address);
        formData.append('piEmail', piEmail);
        formData.append('piName', piName);
        formData.append('specialty', specialty);
        formData.append('crcName', crcName);
        formData.append('crcEmail', crcEmail);
        formData.append('additionalComment', additionalComment);
        formData.append('crcPhone', crcPhone);
        formData.append('state', state);
        formData.append('city', city);
        formData.append('sn', sn);
        formData.append('zip', zip);
        // formData.append('snpa', snpa);

        // console.log(file)

        if (file) {
            formData.append('file', file);
        }
        setTimeout(async () => {
            try {
                const resp = await API.createSiteSubmisson(formData);
                if (resp.status === 200) {
                    toast.success(resp.data.message);
                    // navigate("/site-submisson");
                }
            } catch (e) {
                toast.error(e.response.data.message);
            } finally {
                setIsModalOpen(false);
            }
        }, 1000
        );
    };

    return (

        <>
            {isModalOpen && <Modal />}
            <StudyStyled>
                <div className="Business-Main-Section">
                    <div className="Business-Container">
                        <div className="Main-Detail-Box">
                            <div className='Header-Form'>
                                <img className="Image" src={Logo} alt="" />
                                <span className='Header-text'>Join A Trial! </span>
                            </div>
                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        Study Name <strong className="star">*</strong></span>
                                    {/* <div className={`FormBox ${getInputClass(studyName)}`} */}
                                    <div className={`FormBox ${getInputClass(studyName)}`}
                                    // style={{
                                    //     borderColor: studyName === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <input

                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter Study Name"
                                            value={studyName}
                                            onChange={handleStudyNameChange}

                                        />
                                        {/* {studiesWithSerialNumber?.map((item) => (
                                                <Option key={item._id} value={item.studyName}>
                                                    {item.studyName}
                                                </Option> */}
                                        {/* ))}
                                        </Select> */}

                                    </div>
                                    <div>{charCount1}/{maxChars} characters</div>
                                </div>
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">

                                        Site Name <strong className="star">*</strong></span>
                                    <div className={`FormBox ${getInputClass(siteName)}`}
                                    // style={{
                                    //     borderColor: siteName === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter Site Name"

                                            value={siteName}
                                            onChange={handleSiteNameChange}
                                        />
                                    </div>
                                    <div>{charCount2}/{maxChars} characters</div>
                                </div>

                            </div>
                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">

                                        Address <strong className="star">*</strong></span>
                                    <div className={`FormBox ${getInputClass(address)}`}
                                    // style={{
                                    //     borderColor: address === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter Address"
                                            value={address}

                                            onChange={handleAddressChange}
                                        />
                                    </div>
                                    <div>{charCount3}/{maxChars} characters</div>
                                </div>
                                <div className="Names-Form-1">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">City <strong className="star">*</strong></span>
                                        <div className={`FormBox-1 ${getInputClass(city)}`}
                                        // style={{
                                        //     borderColor: city === "" ? "red" : "#e6ebf2"
                                        // }}
                                        >
                                            <input
                                                className="NameBox-1"
                                                type="Name"
                                                placeholder="Enter City"
                                                value={city}
                                                onChange={handleInput1Change}

                                            />
                                        </div>

                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">State <strong className="star">*</strong></span>
                                        <div className={`FormBox-1 ${getInputClass(state)}`}
                                        // style={{
                                        //     borderColor: state === "" ? "red" : "#e6ebf2"
                                        // }}
                                        >
                                            <input
                                                className="NameBox-1"
                                                type="Name"
                                                placeholder="Enter State"
                                                value={state}
                                                onChange={handleInputChange}
                                            />

                                        </div>

                                    </div>

                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">Zip Code <strong className="star">*</strong></span>
                                        <div className={`FormBox-1 ${getInputClass(zip)}`}
                                        // style={{
                                        //     borderColor: zip === "" ? "red" : "#e6ebf2"
                                        // }}
                                        >
                                            <input
                                                className="NameBox-1"
                                                type="number"
                                                placeholder="Enter Zip"
                                                value={zip}

                                                onKeyPress={handleKeyPress}
                                                onChange={(e) => setZip(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        PI Name <strong className="star">*</strong></span>
                                    <div className={`FormBox ${getInputClass(piName)}`}
                                    // style={{
                                    //     borderColor: piName === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter PI Name"
                                            value={piName}

                                            onChange={handlePiNameChange}
                                        />
                                    </div>
                                    <div>{charCount4}/{maxChars} characters</div>
                                </div>

                            </div>

                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        PI Email <strong className="star">*</strong></span>
                                    <div className={`FormBox ${getInputClass(piEmail)}`}
                                    // style={{
                                    //     borderColor: piEmail === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter PI Email"
                                            value={piEmail}

                                            onChange={(e) => setPiEmail(e.target.value)}
                                        />
                                    </div>

                                </div>
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        Specialty <strong className="star">*</strong> </span>
                                    <div className={`FormBox ${getInputClass(specialty)}`}
                                    // style={{
                                    //     borderColor: specialty === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter Specialty"
                                            value={specialty}

                                            onChange={handleSpecialtyChange}
                                        />
                                    </div>
                                    <div>{charCount5}/{maxChars} characters</div>
                                </div>

                            </div>
                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        CRC Name <strong className="star">*</strong></span>
                                    <div className={`FormBox ${getInputClass(crcName)}`}
                                    // style={{
                                    //     borderColor: crcName === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter CRC Name"
                                            value={crcName}

                                            onChange={handleCrcNameChange}
                                        />
                                    </div>
                                    <div>{charCount6}/{maxChars} characters</div>
                                </div>
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">

                                        CRC Email <strong className="star">*</strong></span>
                                    <div className={`FormBox ${getInputClass(crcEmail)}`}
                                    // style={{
                                    //     borderColor: crcEmail === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <input
                                            className="NameBox"
                                            type="Email"
                                            placeholder="Enter CRC Email"
                                            value={crcEmail}

                                            onChange={(e) => setCrcEmail(e.target.value)}
                                        />



                                    </div>
                                </div>

                            </div>
                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        CRC Phone <strong className="star">*</strong></span>
                                    <div className={`FormBox ${getInputClass(crcPhone)}`}
                                    // style={{
                                    //     borderColor: crcPhone === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <input
                                            className="NameBox"
                                            type="number"
                                            placeholder="Enter CRC Phone"
                                            value={crcPhone}
                                            onChange={(e) => setCrcPhone(e.target.value)}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                </div>


                            </div>

                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        Additional Comments</span>
                                    <div className="FormBox-Comment"
                                    // style={{
                                    //     borderColor: additionalComment === "" ? "red" : "#e6ebf2"
                                    // }}
                                    >
                                        <textarea
                                            className="NameBox-Comment"
                                            placeholder="Add your Additional Comment"
                                            value={additionalComment}
                                            onChange={handleCommentChange}
                                            rows={5}
                                        ></textarea>
                                    </div>
                                    <div>{charCount}/{maxChars} characters</div>
                                </div>
                                <div className="Names-Form-Last">
                                    <div className='Form-cv'>
                                        <span className='Title'>CV <strong className="star">*</strong></span>
                                        <input
                                            type="file"
                                            className="NameBox-cv"
                                            name="file"
                                            id="fileInput"
                                            onChange={handleFileChange}
                                        />
                                        {file && (
                                            <div className='RemoveFile' onClick={handleRemoveFile}>
                                                <FaTrashAlt className='RemoveIcon' />
                                                <span>Remove selected file</span>
                                            </div>
                                        )}
                                    </div>


                                </div>

                            </div>



                            <div className="Names-Form">


                                {/* <div className='Icons-Section'>
                                    <div className="status-icons">
                                        <span className='Title'>SNPA</span>

                                        <span className={`icon ${snpa == true ? 'highlight' : ''} success`}
                                            onClick={() => setSnpa(true)}>
                                            ✔
                                        </span>
                                        <span className={`icon ${snpa == false ? 'highlight' : ''} error`}
                                            onClick={() => setSnpa(false)}>
                                            ✖
                                        </span>
                                    </div>

                                </div> */}
                                {/* <div className='Check-Box-Section'>
                                    <input className="Checkbox" type="checkbox" />
                                    <span className='Title'>Is There Ready for Pi Submisson</span>


                                </div> */}

                            </div>
                            <div className="pagination">
                                {/* <button className='Button-1' onClick={() => navigate("/site-submisson")}>Cancel</button> */}
                                <button className="Button-2" onClick={(e) => oncreateSiteSubmisson(e)} >Submit</button>

                            </div>

                        </div>
                        <div className="CopyRight-Text">
                            © Copyright Cliniverse Research, All Rights Reserved I  <strong className="link">Privacy Policy</strong> I  <strong className="link"> Terms of Use</strong>
                        </div>
                    </div>
                </div>
            </StudyStyled>
            {/* </Sidebar > */}
        </>
    )
}


export default AddSiteSubmisson