import React, { useState, useEffect } from 'react';
// import Sidebar from "../Sidebar/SideBar"
import { useParams } from 'react-router-dom';
import { StudyStyled } from "./style"
import Logo from "../../../../images/login-logo.png"
import { useNavigate } from "react-router-dom";
import { API } from "../../../../api/api";
import { toast } from "react-toastify";
import { FaTrashAlt } from 'react-icons/fa';
function AddSiteSubmisson() {
    const navigate = useNavigate();
    const [studyName, setStudyName] = useState("");
    const [siteName, setSiteName] = useState("");
    const [address, setAddress] = useState("");
    const [piEmail, setPiEmail] = useState("");
    const [piName, setPiName] = useState("");
    const [specialty, setSpecialty] = useState("");
    const [crcName, setCrcName] = useState("");
    const [crcEmail, setCrcEmail] = useState("");
    const [additionalComment, setAdditionalComment] = useState("");
    const [studyManagerComment, setStudyManagerComment] = useState("");
    const [crcPhone, setCrcPhone] = useState("");
    const [state, setState] = useState("");
    const [cv, setCv] = useState("");
    const [snpa, setSnpa] = useState("");
    const [file, setFile] = useState(null);
    const [experience, setExperince] = useState("");
    const [status, setStatus] = useState("");
    const [submission, setSubmisson] = useState({});
    const [isReadyForPISubmission, setisReadyForPISubmission] = useState("");
    const [active, setActive] = useState("");
    const [bd, setBd] = useState("");
    const [charCount1, setCharCount1] = useState(0);
    const [charCount2, setCharCount2] = useState(0);
    const [charCount3, setCharCount3] = useState(0);
    const [charCount4, setCharCount4] = useState(0);
    const [charCount5, setCharCount5] = useState(0);
    const [charCount6, setCharCount6] = useState(0);
    const [charCount7, setCharCount7] = useState(0);
    const [charCount8, setCharCount8] = useState(0);
    const [charCount9, setCharCount9] = useState(0);
    const [charCount10, setCharCount10] = useState(0);
    const maxChars = 200;
    let { id } = useParams();
    useEffect(() => {
        if (submission.studyName) {
            setCharCount1(submission.studyName.length);
        }
    }, [submission.studyName]);

    const handleStudyNameChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setStudyName(value);
            setCharCount1(value.length);

            const updateSubmisson = { ...submission, studyName: value };
            setSubmisson(updateSubmisson);
        }
    };
    useEffect(() => {
        if (submission.siteName) {
            setCharCount2(submission.siteName.length);
        }
    }, [submission.siteName]);

    const handleSiteNameChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setSiteName(value);
            setCharCount2(value.length);
            const updateSubmisson = { ...submission, siteName: value };
            setSubmisson(updateSubmisson);
        }
    };

    // const handleSiteNameChange = (e) => {
    //     const updateSubmisson = { ...submission, siteName: e.target.value };
    //     setSubmisson(updateSubmisson);
    // };
    useEffect(() => {
        if (submission.address) {
            setCharCount3(submission.address.length);
        }
    }, [submission.address]);

    const handleAddressChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setAddress(value);
            setCharCount3(value.length);
        }
        const updateSubmisson = { ...submission, address: value.slice(0, maxChars) }; // Limiting address to maxChars characters
        setSubmisson(updateSubmisson);
    };

    // const handleAdressChange = (e) => {
    //     const updateSubmisson = { ...submission, address: e.target.value };
    //     setSubmisson(updateSubmisson);
    // };

    const handlePiEmailChange = (e) => {
        const updateSubmisson = { ...submission, piEmail: e.target.value };
        setSubmisson(updateSubmisson);
    };
    useEffect(() => {
        if (submission.piName) {
            setCharCount4(submission.piName.length);
        }
    }, [submission.piName]);

    const handlePiNameChange = (e) => {
        let value = e.target.value;
        if (value.length > maxChars) {
            value = value.slice(0, maxChars); // Truncate to maxChars characters
        }
        setPiName(value);
        setCharCount4(value.length);

        const updateSubmission = { ...submission, piName: value };
        setSubmisson(updateSubmission);
    };

    useEffect(() => {
        if (submission.specialty) {
            setCharCount5(submission.specialty.length);
        }
    }, [submission.specialty]);

    const handleSpecialtyChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setSpecialty(value);
            setCharCount5(value.length);

            const updateSubmisson = { ...submission, specialty: value };
            setSubmisson(updateSubmisson);
        }
    };


    useEffect(() => {
        if (submission.crcName) {
            setCharCount6(submission.crcName.length);
        }
    }, [submission.crcName]);
    const handleCrcNameChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setCrcName(value);
            setCharCount6(value.length);

            const updateSubmisson = { ...submission, crcName: value };
            setSubmisson(updateSubmisson);
        }
    };


    const handleCrcEmailChange = (e) => {
        const updateSubmisson = { ...submission, crcEmail: e.target.value };
        setSubmisson(updateSubmisson);
    };

    const handleCrcPhoneChange = (e) => {
        const updateSubmisson = { ...submission, crcPhone: e.target.value };
        setSubmisson(updateSubmisson);
    };
    const handleCvChange = (e) => {
        const updateSubmisson = { ...submission, cv: e.target.value };
        setSubmisson(updateSubmisson);
    };

    useEffect(() => {
        if (submission.state) {
            setCharCount7(submission.state.length);
        }
    }, [submission.state]);
    const handleStateChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setState(value);
            setCharCount7(value.length);

            const updateSubmisson = { ...submission, state: value };
            setSubmisson(updateSubmisson);
        }
    };

    // const handleStateChange = (e) => {
    //     const updateSubmisson = { ...submission, state: e.target.value };
    //     setSubmisson(updateSubmisson);
    // };

    useEffect(() => {
        if (submission.additionalComment) {
            setCharCount8(submission.additionalComment.length);
        }
    }, [submission.additionalComment]);
    const handleAdditionalCommentChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setAdditionalComment(value);
            setCharCount8(value.length);

            const updateSubmisson = { ...submission, additionalComment: value };
            setSubmisson(updateSubmisson);
        }
    };

    // const handleAdditionalCommentChange = (e) => {
    //     const updateSubmisson = { ...submission, additionalComment: e.target.value };
    //     setSubmisson(updateSubmisson);
    // };

    useEffect(() => {
        if (submission.studyManagerComment) {
            setCharCount9(submission.studyManagerComment.length);
        }
    }, [submission.studyManagerComment]);
    const handleStudyManagerCommentChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setStudyManagerComment(value);
            setCharCount9(value.length);

            const updateSubmisson = { ...submission, studyManagerComment: value };
            setSubmisson(updateSubmisson);
        }
    };



    useEffect(() => {
        if (submission.experience) {
            setCharCount10(submission.experience.length);
        }
    }, [submission.experience]);
    const handleExperinceChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setExperince(value);
            setCharCount10(value.length);

            const updateSubmisson = { ...submission, experience: value };
            setSubmisson(updateSubmisson);
        }
    };

    // const handleExperinceChange = (e) => {
    //     const updateSubmisson = { ...submission, experience: e.target.value };
    //     setSubmisson(updateSubmisson);
    // };
    const handleStatusChange = (e) => {
        const updateSubmisson = { ...submission, status: e.target.value };
        setSubmisson(updateSubmisson);
    };
    const handleBdChange = (e) => {
        const updateSubmisson = { ...submission, bd: e.target.value };
        setSubmisson(updateSubmisson);
    };
    const handleSnpaChange = (e, boolean) => {
        if (boolean == true) {
            const updateSubmisson = { ...submission, snpa: true };
            setSubmisson(updateSubmisson);
        } else {
            const updateSubmisson = { ...submission, snpa: false };
            setSubmisson(updateSubmisson);
        }


    };


    const handleIsReadyForPISubmissionChange = (e) => {
        const isChecked = e.target.checked;
        const updateSubmission = { ...submission, isReadyForPISubmission: isChecked };
        console.log(submission.isReadyForPISubmission);
        setSubmisson(updateSubmission);
    };
    const handleActiveChange = (e) => {
        const isChecked = e.target.checked;
        const updateSubmission = { ...submission, active: isChecked };
        console.log(submission.active);
        setSubmisson(updateSubmission);
    };
    const handleRemoveFile = () => {
        setFile(null);
        document.getElementById('fileInput').value = '';
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    useEffect(() => {
        getSubmisson(id);
    }, []);
    const getSubmisson = async (id) => {

        await API.getSubmisson(id,
        )
            .then((resp) => {
                console.log();
                if (resp.status == 200) {
                    setSubmisson(resp.data.data);
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };




    const updateSubmisson = async () => {
        submission.id = submission._id;
        const formData = new FormData()
        formData.append('id', submission._id);
        formData.append('studyName', submission.studyName);
        formData.append('siteName', submission.siteName);
        formData.append('address', submission.address);
        formData.append('piEmail', submission.piEmail);
        formData.append('piName', submission.piName);
        formData.append('piAddress', submission.piAddress);
        formData.append('specialty', submission.specialty);
        formData.append('crcName', submission.crcName);
        formData.append('crcEmail', submission.crcEmail);
        formData.append('experience', submission.experience);
        formData.append('additionalComment', submission.additionalComment);
        formData.append('studyManagerComment', submission.studyManagerComment);
        formData.append('crcPhone', submission.crcPhone);
        formData.append('state', submission.state);
        formData.append('city', submission.city);
        formData.append('email', submission.email);
        formData.append('zip', submission.zip);
        formData.append('snpa', submission.snpa);
        formData.append('status', submission.status);
        formData.append('isReadyForPISubmission', submission.isReadyForPISubmission);
        formData.append('bd', submission.bd);
        formData.append(' active', submission.active);
        console.log(file)

        if (file) {
            formData.append('file', file);
        }
        await API.updateSubmisson(formData)

            .then((resp) => {
                // console.log();
                if (resp.status == 200) {
                    // console.log(resp.data.data);
                    // setLead(resp.data.data);
                    getSubmisson(id);
                    navigate("/site-submisson");
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };

    return (

        <>

            <StudyStyled>
                <div className="Business-Main-Section">
                    <div className="Business-Container">

                        <div className="Main-Detail-Box">

                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        Study Name</span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter Study Name"
                                            value={
                                                submission.studyName ? submission.studyName : studyName
                                            }
                                            onChange={handleStudyNameChange}
                                        />
                                    </div>
                                    <div>{charCount1}/{maxChars} characters</div>
                                </div>
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">

                                        Site Name</span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter Site Name"
                                            value={
                                                submission.siteName ? submission.siteName : siteName
                                            }
                                            onChange={handleSiteNameChange}

                                        />
                                    </div>
                                    <div>{charCount2}/{maxChars} characters</div>
                                </div>

                            </div>
                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">

                                        Address</span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter Address"


                                            value={
                                                submission.address ? submission.address : address
                                            }
                                            onChange={handleAddressChange}

                                        />
                                    </div>
                                    <div>{charCount3}/{maxChars} characters</div>
                                </div>
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        PI Name</span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter PI Name"

                                            value={
                                                submission.piName ? submission.piName : piName
                                            }
                                            onChange={handlePiNameChange}

                                        />
                                    </div>
                                    <div>{charCount4}/{maxChars} characters</div>
                                </div>

                            </div>
                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom-1">
                                    <span className="Form-Box-Title">
                                        PI Email</span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter PI Email"

                                            value={
                                                submission.piEmail ? submission.piEmail : piEmail
                                            }
                                            onChange={handlePiEmailChange}

                                        />
                                    </div>
                                </div>
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        Specialty </span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter Specialty"

                                            value={
                                                submission.specialty ? submission.specialty : specialty
                                            }
                                            onChange={handleSpecialtyChange}

                                        />
                                    </div>
                                    <div>{charCount5}/{maxChars} characters</div>
                                </div>

                            </div>
                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        CRC Name</span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter Crc Name"
                                            value={
                                                submission.crcName ? submission.crcName : crcName
                                            }
                                            onChange={handleCrcNameChange}

                                        />
                                    </div>
                                    <div>{charCount6}/{maxChars} characters</div>
                                </div>
                                <div className="Form-Box-Text-Bottom-1">
                                    <span className="Form-Box-Title">

                                        CRC Email</span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            type="Email"
                                            placeholder="Enter Crc Email"


                                            value={
                                                submission.crcEmail ? submission.crcEmail : crcEmail
                                            }
                                            onChange={handleCrcEmailChange}

                                        />



                                    </div>
                                </div>

                            </div>
                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom-1">
                                    <span className="Form-Box-Title">

                                        CRC Phone</span>
                                    <div className="FormBox">
                                        <input

                                            className="NameBox"
                                            type="number"
                                            placeholder="Enter Crc Phone"

                                            value={
                                                submission.crcPhone ? submission.crcPhone : crcPhone
                                            }
                                            onChange={handleCrcPhoneChange}

                                        />



                                    </div>
                                </div>
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">State</span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter State"

                                            value={
                                                submission.state ? submission.state : state
                                            }
                                            onChange={handleStateChange}



                                        />
                                    </div>
                                    <div>{charCount7}/{maxChars} characters</div>
                                </div>

                            </div>

                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        Additional Comment</span>
                                    <div className="FormBox-Comment">

                                        <textarea
                                            className="NameBox-Comment"
                                            placeholder="Add your Additional Comment"

                                            value={
                                                submission.additionalComment ? submission.additionalComment : additionalComment
                                            }
                                            onChange={handleAdditionalCommentChange}

                                            rows={5}
                                        ></textarea>

                                    </div>
                                    <div>{charCount8}/{maxChars} characters</div>
                                </div>
                                <div className="Names-Form-Last">
                                    <div className="Form-cv">
                                        <span className='Title'>Medical Certificite</span>

                                        <input
                                            type="file"
                                            className="NameBox-cv"
                                            name="file"
                                            id="fileInput"
                                            onChange={handleFileChange}

                                        />
                                        {file && (
                                            <div className='RemoveFile' onClick={handleRemoveFile}>
                                                <FaTrashAlt className='RemoveIcon' />
                                                <span>Remove selected file</span>
                                            </div>
                                        )}

                                    </div>


                                </div>

                            </div>
                            <div className="Names-Form">
                                <div className="Icons-Section">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            Study Manager Comment</span>
                                        <div className="FormBox-Comment">

                                            <textarea
                                                className="NameBox-Comment"
                                                placeholder="Add your Study Manager Comment"

                                                value={
                                                    submission.studyManagerComment ? submission.studyManagerComment : studyManagerComment
                                                }
                                                onChange={handleStudyManagerCommentChange}

                                                rows={5}
                                            ></textarea>

                                        </div>
                                        <div>{charCount9}/{maxChars} characters</div>
                                    </div>
                                </div>
                            </div>
                            <div className="Names-Form">
                                <div className='Icons-Section'>

                                    <div className="status-icons">
                                        <span className='Title'>SNPA</span>
                                        <span
                                            className={`icon ${submission.snpa == true ? 'highlight' : ''} success`}
                                            onClick={(e) => handleSnpaChange(e, true)}
                                        >
                                            ✔
                                        </span>
                                        <span
                                            className={`icon ${submission.snpa == false ? 'highlight' : ''} error`}
                                            onClick={(e) => handleSnpaChange(e, false)}
                                        >
                                            ✖
                                        </span>
                                    </div>

                                </div>


                            </div>
                            <div className="Names-Form">
                                <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        Experience</span>
                                    <div className="FormBox">
                                        <input
                                            className="NameBox"
                                            placeholder='Enter Experience'
                                            value={
                                                submission.experience ? submission.experience : experience}
                                            onChange={handleExperinceChange}
                                        />




                                    </div>
                                    <div>{charCount10}/{maxChars} characters</div>
                                </div>
                                <div className="Form-Box-Text-Bottom-1">
                                    <span className="Form-Box-Title">  Status</span>
                                    <div className="FormBox">
                                        <select
                                            className="NameBox"
                                            type="Name"
                                            placeholder="Enter State"
                                            onChange={handleStatusChange}
                                            value={
                                                submission.status ? submission.status : status
                                            }
                                        >
                                            <option value="">Select Status
                                            </option>
                                            <option>Feasibility
                                            </option>
                                            <option>Budget / Contact = Regulatory
                                            </option>
                                            <option>SSV - Selected
                                            </option>
                                            <option>SSV - Not Selected</option>
                                        </select>



                                    </div>
                                </div>


                            </div>
                            <div className="Names-Form">



                                <div className='Icons-Section'>
                                    <div className="Form-Box-Text-Bottom-1">
                                        <span className="Form-Box-Title">  BD</span>
                                        <div className="FormBox">
                                            <select
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter State"


                                                onChange={handleBdChange}
                                                value={
                                                    submission.bd ? submission.bd : bd
                                                }
                                            >
                                                <option value="">Select BD
                                                </option>
                                                <option>Direct
                                                </option>
                                                <option>Arsymed

                                                </option>
                                                <option>Brian Norton
                                                </option>

                                            </select>



                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="Names-Form-checkbox">
                                <div className='Check-Box-Containers'>
                                    <div className='Check-Box-Section'>
                                        <input
                                            className="Checkbox"
                                            type="checkbox"
                                            checked={submission.isReadyForPISubmission}
                                            onChange={handleIsReadyForPISubmissionChange}
                                        />
                                        <span className='Title'>  Ready for PI Submisson</span>
                                    </div>
                                    <div className='Check-Box-Section'>
                                        <span className='Title'>  Active</span>
                                        <input
                                            className="Checkbox"
                                            type="checkbox"
                                            checked={submission.active}
                                            onChange={handleActiveChange}
                                        />

                                    </div></div>
                            </div>

                            <div className="pagination">
                                <button className='Button-1' onClick={() => navigate("/site-submisson")}>Cancel</button>
                                <button className="Button-2" onClick={(e) => updateSubmisson(e)}>Submit</button>

                            </div>
                        </div>
                    </div>
                </div>
            </StudyStyled >

        </>
    )
}


export default AddSiteSubmisson