import React, { useState } from 'react';
import { API } from "../../../../api/api";
import { toast } from "react-toastify";
import Sidebar from "../../../Sidebar/SideBar"
import { StudyStyled } from "./style"
import { IoPersonSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function AddReminder() {
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("user"));
    const [title, setTitle] = useState("");
    const [dateAndTime, setDateAndTime] = useState("");
    const [repeating, setRepeating] = useState("");
    const [description, setDescription] = useState("");
    const [selectedDay, setSelectedDay] = useState("");
    const [charCount, setCharCount] = useState(0);
    const [charCount1, setCharCount1] = useState(0);
    const maxChars = 200;
    const handleRepeatingChange = (event) => {
        setRepeating(event.target.value);
    };

    // const handleTitleChange = (event) => {
    //     setTitle(event.target.value);
    // };
    const handleDateAndTimeChange = (event) => {
        setDateAndTime(event.target.value);
    };
    // const handleRepeatingChange = (event) => {
    //     setRepeating(event.target.value);
    // };
    // const handleDescriptionChange = (e) => {
    //     setDescription(e.target.value);
    // };
    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setDescription(value);
            setCharCount1(value.length);
        }
    };
    const handleTitleChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxChars) {
            setTitle(value);
            setCharCount(value.length);
        }
    };
    const oncreateReminderData = async (e) => {
        e.preventDefault();

        if (!title) {
            return toast.error("Please Enter Your Study Name");
        }
        // if (!repeating) {
        //     return toast.error("Please Enter Repeating");
        // }
        if (!dateAndTime) {
            return toast.error("Please Enter Date And Time");
        }
        if (!description) {
            return toast.error("Please Enter Your Description");
        }



        const createReminderData = {
            title,
            dateAndTime,
            repeating,
            description,
        };

        await API.createReminder(createReminderData)
            .then((resp) => {
                if (resp.status == 200) {
                    toast.success(resp.data.message);

                    navigate("/reminder");
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };

    return (

        <>
            <Sidebar>
                <StudyStyled>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div></div>
                                    <div className="Bell-Main-Section">

                                        <div className="Avatar-Main-Section">
                                            <IoPersonSharp className="Manager-Avatar" />
                                            <div className="Avatar-Text-Section">
                                                {userData.userName}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="Call-Center-Title">
                                    <span className="call-center">Add Reminder</span>

                                </div>
                            </div>
                            <div className="Main-Detail-Box">
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            Add Reminder</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Title"
                                                value={title}
                                                onChange={handleTitleChange}
                                            />
                                        </div>
                                        <div>{charCount}/{maxChars} characters</div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Date and Time</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="datetime-local"


                                                value={dateAndTime} onChange={handleDateAndTimeChange}

                                            />
                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Repeating</span>
                                        <div className="FormBox">
                                            <select className="NameBox" value={repeating} onChange={handleRepeatingChange}>
                                                <option value="">Select Day</option>
                                                <option >Every Day</option>
                                                <option >Monday</option>
                                                <option >Tuesday</option>
                                                <option >Wednesday</option>
                                                <option >Thursday</option>
                                                <option >Friday</option>
                                                <option >Saturday</option>
                                                <option >Sunday</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            Add Description</span>
                                        <div className="FormBox-Comment">

                                            <textarea
                                                className="NameBox-Comment"
                                                placeholder="Add your description"
                                                value={description}
                                                onChange={handleDescriptionChange}
                                                rows={5}
                                            ></textarea>

                                        </div>
                                        <div>{charCount1}/{maxChars} characters</div>
                                    </div>
                                </div>



                                <div className="pagination">
                                    <button className='Button-1' onClick={() => navigate("/reminder")}>Cancel</button>
                                    <button className="Button-2" onClick={(e) => oncreateReminderData(e)}>Submit</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </StudyStyled>
            </Sidebar>
        </>
    )
}

export default AddReminder