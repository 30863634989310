import React, { useEffect, useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { MdOutlineAccessTime } from "react-icons/md";
import Sidebar from "../../Sidebar-2/SideBar";
import { ReminderStyled, StudyStyled } from "./style"
import { IoPersonSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FcAlarmClock } from "react-icons/fc";
import { BsCalendarDate } from "react-icons/bs";
import { API } from "../../../api/api";
import { toast } from "react-toastify";
function Reminder() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [allreminder, setAllReminder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reminders, setReminders] = useState([]);
  function formatDate(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Format the date as per your requirements
    const formattedDate = date.toLocaleDateString("en-US", {

      hour: "numeric",
      minute: "numeric",

      hour12: true, // If you want 12-hour format
    });


    return formattedDate;
  }
  function formatDate1(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Format the date as per your requirements
    const formattedDate = date.toLocaleDateString("en-US", {


    });


    return formattedDate;
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user._id;
    getAllReminder();
  },);
  function ReminderComponent({ reminder }) {
    return (
      <ReminderStyled>
        <div className="reminder">
          <div className="reminder-content">
            <div className="Title-Section">
              <FcAlarmClock className="Icon" />
              <p className="Reminder-Title">
                {reminder.title}</p>
            </div>


            <span className="close" onClick={() => closeReminder(reminder._id)}>&times;</span>
          </div>
        </div>
      </ReminderStyled>
    );
  }

  // const getAlluserReminders = async () => {
  //   try {
  //     const response = await API.getAllUserReminder({


  //     });
  //     const responseData = response.data;

  //     if (responseData && responseData.success) {
  //       setReminders(responseData.data);

  //     } else {
  //       // toast.error("Failed to fetch companies");
  //     }
  //   } catch (error) {
  //     // toast.error("Error fetching submissons: " + error.message);
  //   }
  // };

  const closeReminder = async (id) => {
    // socket.emit("toggleReminder", reminderId, true);

    // preventDefault();
    await API.turnOffRemainder(
      id,
    )
      .then((resp) => {
        // console.log(resp);
        if (resp.status == 200) {
          toast.success(resp.data.message);
          // console.log(resp.data.data);
          setReminders(reminders.filter(reminder => reminder._id !== id));
        }
      })
      .catch((e) => toast.error(e.response.data.message));


  };
  useEffect(() => {
    getAllReminder();
  }, []);

  const getAllReminder = async () => {
    await API.getAllUsersReminder()
      .then((resp) => {
        if (resp.status == 200) {
          setAllReminder(resp.data.data);
          // toast.success(resp.data.message);
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  const handleDelete = async (e, id) => {
    e.preventDefault();
    await API.deleteReminder({
      id: id,
    })
      .then((resp) => {
        // console.log(resp);
        if (resp.status == 200) {
          toast.success(resp.data.message);
          // console.log(resp.data.data);
          getAllReminder();
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  return (

    <>
      <Sidebar>
        <StudyStyled>
          <div>
            {reminders?.map((reminder) => (
              <ReminderComponent
                key={reminder._id}
                reminder={reminder}
                onClose={closeReminder}
              />
            ))}
          </div>
          <div className="Business-Main-Section">
            <div className="Business-Container">
              <div className="Sidebar-Header-Content">
                <div className="Sidebar-Header-Section">
                  <div></div>
                  <div className="Bell-Main-Section">

                    <div className="Avatar-Main-Section">
                      <IoPersonSharp className="Manager-Avatar" />
                      <div className="Avatar-Text-Section">
                        <span className="Avatar-Title">
                          {userData.userName}

                        </span>


                      </div>
                    </div>
                  </div>
                </div>

                <div className="Call-Center-Title">
                  <span className="call-center">Reminders</span>

                </div>
              </div>
              <div className="Main-Detail-Box">
                <div className='Main-Content'>
                  <span className="Contact-Title"></span>
                  <button className='Add-File-button' onClick={() => navigate("/create-user-reminder")}> + Add Reminder</button>
                </div>


                <div className='Card-Section' >
                  {allreminder?.map((item, index) => (
                    <div className='Card-Main-Section'>
                      <div className='Container' key={index}>
                        <div className='Title-Main'> <span className="Title">{item.title}</span>  <MdOutlineDelete
                          onClick={(e) => handleDelete(e, item._id)} className="Table-Icons1" /></div>

                        <span className='Card-Title'> <BsCalendarDate className='Card-Icon' /> {formatDate1(item.createdAt)}</span>
                        <span className='Card-Title-1'><MdOutlineAccessTime className='Card-Icon' />{formatDate(item.createdAt)}</span>
                      </div>
                    </div>
                  ))}

                </div>

              </div>
            </div>
          </div>
        </StudyStyled>
      </Sidebar>
    </>
  )
}

export default Reminder