import React, { useState, useEffect } from 'react';
import Sidebar from "../../../Sidebar/SideBar"
import { useParams } from 'react-router-dom';
import { StudyStyled } from "./style"
import Logo from "../../../../images/login-logo.png"
import { useNavigate } from "react-router-dom";
import { API } from "../../../../api/api";
import { toast } from "react-toastify";

function AddSiteSubmisson() {
    const navigate = useNavigate();

    const [siteName, setSiteName] = useState("");

    const [piEmail, setPiEmail] = useState("");
    const [piName, setPiName] = useState("");
    const [sivReadiness, setSivReadiness] = useState("");

    const [contractDate, setContractDate] = useState("");

    const [budgetDate, setBudgetDate] = useState("");
    const [sivDate, setSivDate] = useState("");
    const [submission, setSubmisson] = useState({});



    let { id } = useParams();

    const handleSiteNameChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, siteName: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };


    const handlePiNameChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, piName: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };





    const handleContractDateChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, contractDate: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };
    const handleBudgetDateChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, budgetDate: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };
    const handleSivDateChange = (e) => {
        const updateSiteSubmissionVisit = { ...submission, sivDate: e.target.value };
        setSubmisson(updateSiteSubmissionVisit);
    };

    const handleSivReadinessChange = (e, boolean) => {
        if (boolean == true) {
            const updateSubmisson = { ...submission, sivReadiness: true };
            setSubmisson(updateSubmisson);
        } else {
            const updateSubmisson = { ...submission, sivReadiness: false };
            setSubmisson(updateSubmisson);
        }


    };



    // const handleIsReadyForBudgetOrContractChange = (e) => {
    //     const isChecked = e.target.checked;
    //     const updateSiteSubmissionVisit = { ...submission, isReadyForBudgetOrContract: isChecked };
    //     setSubmisson(updateSiteSubmissionVisit);
    // };
    useEffect(() => {
        getSubmisson(id);
    }, []);
    const getSubmisson = async (id) => {

        await API.getSubmisson(id,
        )
            .then((resp) => {
                console.log();
                if (resp.status == 200) {
                    setSubmisson(resp.data.data);
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };




    const updateContractOrBudget = async () => {
        submission.id = id
        await API.updateContractOrBudget(submission)

            .then((resp) => {
                // console.log();
                if (resp.status == 200) {
                    // console.log(resp.data.data);
                    // setLead(resp.data.data);
                    getSubmisson(id);
                    navigate("/budget-contract");
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };

    return (

        <>
            <Sidebar>
                <StudyStyled>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div></div>
                                    <div className="Bell-Main-Section">
                                    </div>
                                </div>

                                <div className="Call-Center-Title">
                                    <span className="call-center">Update Contract Or Budget</span>

                                </div>
                            </div>
                            <div className="Main-Detail-Box">

                                <div className="Names-Form">

                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Site Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Site Name"
                                                value={
                                                    submission.siteName ? submission.siteName : siteName
                                                }
                                                onChange={handleSiteNameChange}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="Names-Form">

                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            PI Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter PI Name"

                                                value={
                                                    submission.piName ? submission.piName : piName
                                                }
                                                onChange={handlePiNameChange}

                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="Names-Form">

                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Contract Date</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="date"
                                                placeholder="Enter Crc Email"


                                                value={
                                                    submission.contractDate ? submission.contractDate : contractDate
                                                }
                                                onChange={handleContractDateChange}

                                            />



                                        </div>
                                    </div>

                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">Budget Date</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="date"
                                                placeholder="Enter Project Manager Name"

                                                value={
                                                    submission.budgetDate ? submission.budgetDate : budgetDate
                                                }
                                                onChange={handleBudgetDateChange}

                                            />



                                        </div>
                                    </div>



                                </div>
                                <div className="Names-Form">
                                    <div className='Icons-Section'>

                                        <div className="status-icons">
                                            <span className='Title'>Siv Readiness</span>
                                            <span
                                                className={`icon ${submission.sivReadiness == true ? 'highlight' : ''} success`}
                                                onClick={(e) => handleSivReadinessChange(e, true)}
                                            >
                                                ✔
                                            </span>
                                            <span
                                                className={`icon ${submission.sivReadiness == false ? 'highlight' : ''} error`}
                                                onClick={(e) => handleSivReadinessChange(e, false)}
                                            >
                                                ✖
                                            </span>
                                        </div>

                                    </div>


                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            SIV Date</span>
                                        <div className="FormBox">
                                            <input

                                                className="NameBox"
                                                type="datetime-local"

                                                placeholder="Enter Date"

                                                value={
                                                    submission.sivDate ? submission.sivDate : sivDate
                                                }
                                                onChange={handleSivDateChange}

                                            />



                                        </div>
                                    </div>



                                </div>



                                {/* <div className="Names-Form-checkbox">
                                    <div className='Check-Box-Containers'>
                                        <div className='Check-Box-Section'>
                                            <input
                                                className="Checkbox"
                                                type="checkbox"
                                                checked={submission.isReadyForBudgetOrContract}
                                                onChange={handleIsReadyForBudgetOrContractChange}
                                            />
                                            <span className='Title'>Ready for Contract/Budget</span>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="pagination">
                                    <button className='Button-1' onClick={() => navigate("/budget-contract")}>Cancel</button>
                                    <button className="Button-2" onClick={(e) => updateContractOrBudget(e)}>Submit</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </StudyStyled >
            </Sidebar>
        </>
    )
}


export default AddSiteSubmisson