import React, { useState } from "react";
import { Adminstyled } from "./style";
import Loginwallpaper from "../login-wallpaper/Loginwallpaper";
import { Await, useNavigate } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import Adminlogo from "../../images/login-logo.png"
import { API } from "../../api/api";
import { toast } from "react-toastify";

function AdminLogin() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handlemailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const getInputClass = (inputValue) => {
    return formSubmitted && !inputValue ? 'input-error' : '';
  };
  const onLogin = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!email) {
      return toast.error("Please enter your email");
    }

    if (!password) {
      return toast.error("Please enter your password");
    }

    const loginData = {

      email,
      password,
    };

    await API.login(loginData)
      .then((resp) => {
        if (resp.status == 200) {
          localStorage.setItem("user", JSON.stringify(resp.data.data));

          JSON.stringify(localStorage.setItem("token", resp.data.data.token));

          if (resp.data.data.role === "Study Manager") {

            navigate(`/user-managers-studies/${resp.data.data._id}`)

            toast.success(resp.data.message);
          }
          else if (resp.data.data.role === "Super Admin") {
            navigate("/study-manager");
            toast.success(resp.data.message);
          }

        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  return (
    <Loginwallpaper>
      <Adminstyled>
        <div className="Admin-Main-Section">
          <div className="Admin-Main-Container">
            <div className="Admin-Welcome-Text">
              <img className="Sidebar-Logo" src={Adminlogo} alt="" />
              <span className="Welcome-Title">Welcome Back !</span>
              <span className="Welcome-Text">

                Sign In to Clixs to Continue
              </span>
            </div>

            <div className="Sign-Form-Section">

              <form className="Sign-Form">
                <div className="Form-Box-Text">
                  <span className="Form-Box-Title">
                    Email</span>
                  <div className={`FormBox ${getInputClass(email)}`}>

                    <input
                      className="NameBox"
                      type="Email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handlemailChange}
                    />
                  </div>
                </div>
                <div className="Form-Box-Text">
                  <div className="Password-Text-Section">
                    <span className="Password-Text">
                      Password</span>
                    <span
                      className="Forget-Text"
                      onClick={() => navigate("/forget")}
                    >

                      Forgot password?
                    </span>
                  </div>

                  <div className={`FormBox ${getInputClass(password)}`}>
                    <input
                      className="NameBox"
                      type={isPasswordVisible ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Enter password"
                    />
                    {isPasswordVisible ? (
                      <IoEyeOutline className="FormIcon" onClick={togglePasswordVisibility} />
                    ) : (

                      <IoEyeOffOutline className="FormIcon" onClick={togglePasswordVisibility} />
                    )}
                  </div>
                </div>
                <div className="Forget-Text-Section">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span className="Character-Text">
                    Remember me</span>
                </div>
                <div className="Sign-in-Button-Section">
                  <button
                    onClick={(e) => onLogin(e)}

                    className="Sign-In-Button"
                  >

                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Adminstyled>
    </Loginwallpaper>
  )
}

export default AdminLogin