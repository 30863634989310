import React, { useEffect, useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { MdOutlineAccessTime } from "react-icons/md";
import Sidebar from "../../Sidebar/SideBar"
import { StudyStyled } from "./style"
import { IoPersonSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
// import { Card3Array } from '../../Array';
import { BsCalendarDate } from "react-icons/bs";
import { API } from "../../../api/api";
import { toast } from "react-toastify";

function Reminder() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [allreminder, setAllReminder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  function formatDate(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Format the date as per your requirements
    const formattedDate = date.toLocaleDateString("en-US", {

      hour: "numeric",
      minute: "numeric",

      hour12: true, // If you want 12-hour format
    });


    return formattedDate;
  }
  function formatDate1(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Format the date as per your requirements
    const formattedDate = date.toLocaleDateString("en-US", {


    });


    return formattedDate;
  }


  useEffect(() => {
    getAllReminder();
  }, []);

  const getAllReminder = async () => {
    await API.getAllReminder()
      .then((resp) => {
        if (resp.status == 200) {
          setAllReminder(resp.data.data);
          // toast.success(resp.data.message);
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  const handleDelete = async (e, id) => {
    e.preventDefault();
    await API.deleteReminder({
      id: id,
    })
      .then((resp) => {
        // console.log(resp);
        if (resp.status == 200) {
          toast.success(resp.data.message);
          // console.log(resp.data.data);
          getAllReminder();
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };
  return (

    <>
      <Sidebar>
        <StudyStyled>
          <div className="Business-Main-Section">
            <div className="Business-Container">
              <div className="Sidebar-Header-Content">
                <div className="Sidebar-Header-Section">
                  <div></div>
                  <div className="Bell-Main-Section">

                    <div className="Avatar-Main-Section">
                      <IoPersonSharp className="Manager-Avatar" />
                      <div className="Avatar-Text-Section">
                        <span className="Avatar-Title">
                          {userData.userName}

                        </span>


                      </div>
                    </div>
                  </div>
                </div>

                <div className="Call-Center-Title">
                  <span className="call-center">Reminders</span>

                </div>
              </div>
              <div className="Main-Detail-Box">
                <div className='Main-Content'>
                  <span className="Contact-Title"></span>
                  <button className='Add-File-button' onClick={() => navigate("/add-reminder")}> + Add Reminder</button>
                </div>


                <div className='Card-Section' >
                  {allreminder?.map((item, index) => (
                    <div className='Card-Main-Section'>
                      <div className='Container' key={index}>
                        <div className='Title-Main'> <span className="Title">{item.title}</span>  <MdOutlineDelete
                          onClick={(e) => handleDelete(e, item._id)} className="Table-Icons1" /></div>

                        <span className='Card-Title'> <BsCalendarDate className='Card-Icon' /> {formatDate1(item.createdAt)}</span>
                        <span className='Card-Title-1'><MdOutlineAccessTime className='Card-Icon' />{formatDate(item.createdAt)}</span>
                      </div>
                    </div>
                  ))}

                </div>

              </div>
            </div>
          </div>
        </StudyStyled>
      </Sidebar>
    </>
  )
}

export default Reminder