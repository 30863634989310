import React, { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar/SideBar"
import { useParams } from 'react-router-dom';
import { StudyStyled } from "./style"
import Logo from "../../../../images/login-logo.png"
import { useNavigate } from "react-router-dom";
import { API } from "../../../../api/api";
import { toast } from "react-toastify";
import { Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FaTrashAlt } from 'react-icons/fa';
function AddCda() {
    const { Option } = Select;
    const navigate = useNavigate();
    const [studyName, setStudyName] = useState("");
    const [studiesWithSerialNumber, setStudiesWithSerialNumber] = useState([]);
    const [sponsorName, setSponsorName] = useState("");
    const [cdaExecutionDate, setCdaExecutionDate] = useState("");
    const [cdaExpirationDate, setCdaExpirationDate] = useState("");
    const [cda, setCda] = useState("");

    const [file, setFile] = useState(null);

    const user = localStorage.getItem("user")

    let { id } = useParams();
    const MAX_COUNT = Infinity;
    const suffix = (
        <>
            <span>{studiesWithSerialNumber.length} / {MAX_COUNT}</span>
            <DownOutlined />
        </>
    );
    const handleRemoveFile = () => {
        setFile(null);
        document.getElementById('fileInput').value = '';
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const handleStudyNameChange = (e) => {
        const updateCDA = { ...cda, studyName: e.target.value };
        setCda(updateCDA);
    };

    const handleSponsorNameChange = (e) => {
        const updateCDA = { ...cda, sponsorName: e.target.value };
        setCda(updateCDA);
    };
    const handleCdaExecutionDateChange = (e) => {
        const updateCDA = { ...cda, cdaExecutionDate: e.target.value };
        setCda(updateCDA);
    };
    const handleCdaExpirationDateChange = (e) => {
        const updateCDA = { ...cda, cdaExpirationDate: e.target.value };
        setCda(updateCDA);
    };
    useEffect(() => {
        getAllStudiesWithoutPagination();
        getCdaById(id);
    }, []);
    const getAllStudiesWithoutPagination = async () => {
        await API.getAllStudiesWithoutPagination()
            .then((resp) => {
                if (resp.status == 200) {
                    setStudiesWithSerialNumber(resp.data.data);
                    // toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };



    const getCdaById = async (id) => {

        await API.getCdaById(id,
        )
            .then((resp) => {
                console.log();
                if (resp.status == 200) {
                    setCda(resp.data.data);
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };

    const updateCDA = async (e) => {
        const formData = new FormData()
        formData.append('type', "sponsor");
        formData.append('id', id);
        formData.append('studyName', studyName);
        formData.append('sponsorName', cda.sponsorName);
        formData.append('cdaExecutionDate', cda.cdaExecutionDate);
        formData.append('cdaExpirationDate', cda.cdaExpirationDate);



        if (file) {
            formData.append('file', file);
        }
        e.preventDefault();
        try {

            const resp = await API.updateCDA(formData);
            if (resp.status === 200) {
                navigate("/cda-sponsor");
                toast.success(resp.data.message);
            }
        } catch (e) {
            toast.error(e.response.data.message);
        }
    };
    return (

        <>
            <Sidebar>
                <StudyStyled>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div></div>
                                    <div className="Bell-Main-Section">
                                    </div>
                                </div>

                                <div className="Call-Center-Title">
                                    <span className="call-center">Update Sponsor CDA</span>

                                </div>
                            </div>
                            <div className="Main-Detail-Box">
                                <div className='Header-Form'>
                                </div>
                                <div className="Names-Form">
                                    {/* <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            Study Name</span>
                                        <div className="FormBox">
                                            <select
                                                className="NameBox"
                                                placeholder="Select Study"
                                                value={
                                                    cda.studyName ? cda.studyName : studyName
                                                }
                                                onChange={handleStudyNameChange}

                                            >
                                                {studiesWithSerialNumber?.map((item) => (
                                                    <option key={item._id} value={item.studyName}>
                                                        {item.studyName}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                    </div> */}
                                    {/* <div className="Form-Box-Text-Bottom">
                                    <span className="Form-Box-Title">
                                        Study Name</span>
                                    <div className="FormBox-select">
                                        <Select
                                            className="NameBox-select"
                                            value={studyName}
                                            onChange={(value) => setStudyName(value)}
                                            mode="multiple"
                                            suffixIcon={suffix}
                                            placeholder="Select Study"
                                        >
                                            {studiesWithSerialNumber?.map((item) => (
                                                <Option key={item._id} value={item.studyName}>
                                                    {item.studyName}
                                                </Option>
                                            ))}
                                        </Select>

                                    </div>
                                </div> */}
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Study Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Site Name"
                                                value={
                                                    cda.studyName ? cda.studyName : studyName
                                                }
                                                onChange={handleStudyNameChange}

                                            />
                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Sponsor Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Sponsor Name"
                                                value={
                                                    cda.sponsorName ? cda.sponsorName : sponsorName
                                                }

                                                onChange={handleSponsorNameChange}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="Names-Form">

                                    <div className="Names-Form-Last">
                                        <div className="Form-cv">
                                            <span className='Title'>CDA</span>
                                            <input
                                                type="file"
                                                className="NameBox-cv"
                                                name="file"
                                                id="fileInput"
                                                onChange={handleFileChange}

                                            />
                                            {file && (
                                                <div className='RemoveFile' onClick={handleRemoveFile}>
                                                    <FaTrashAlt className='RemoveIcon' />
                                                    <span>Remove selected file</span>
                                                </div>
                                            )}

                                        </div>


                                    </div>

                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            CDA Execution Date</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="date"
                                                placeholder="Enter PI Email"
                                                value={
                                                    cda.cdaExecutionDate ? cda.cdaExecutionDate : cdaExecutionDate
                                                }
                                                onChange={handleCdaExecutionDateChange}

                                            />
                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            CDA Expiration Date </span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="date"
                                                placeholder="Enter Specialty"
                                                value={
                                                    cda.cdaExpirationDate ? cda.cdaExpirationDate : cdaExpirationDate
                                                }
                                                onChange={handleCdaExpirationDateChange}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="pagination">
                                    <button className='Button-1' onClick={() => navigate("/cda-sponsor")}>Cancel</button>
                                    <button className="Button-2" onClick={(e) => updateCDA(e)}>Submit</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </StudyStyled>
            </Sidebar >
        </>
    )
}


export default AddCda