import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/SideBar"
import { ReminderStyled, StudyStyled } from "./Style"
import { Await, useNavigate } from "react-router-dom";
import { IoPersonSharp } from "react-icons/io5";
import { FcAlarmClock } from "react-icons/fc";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useParams } from "react-router-dom";
import { API } from "../../api/api";
import { toast } from "react-toastify";
import { IoIosPerson } from "react-icons/io";



function StudyManager() {
  function formatDate(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Format the date as per your requirements
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, // If you want 12-hour format
    });

    return formattedDate;
  }
  const navigate = useNavigate();
  const [allmanager, setAllManager] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let { id } = useParams();
  const userData = JSON.parse(localStorage.getItem("user"));
  console.log(userData, " user data")
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Text copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };


  const getAllReminder = async () => {
    try {
      const response = await API.getAllUserReminder({


      });
      const responseData = response.data;

      if (responseData && responseData.success) {
        setReminders(responseData.data);

      } else {
        // toast.error("Failed to fetch companies");
      }
    } catch (error) {
      // toast.error("Error fetching submissons: " + error.message);
    }
  };

  function ReminderComponent({ reminder }) {
    return (
      <ReminderStyled>
        <div className="reminder">
          <div className="reminder-content">
            <div className="Title-Section">
              <IoIosPerson className="Icon" />
              <p className="Reminder-Title">
                {reminder.title}</p>

            </div>
            <div className="Title-Section">
              <FcAlarmClock className="Icon" />
              <p className="Reminder-Title">
                {formatDate(reminder.dateAndTime)}
              </p>

            </div>

            <span className="close" onClick={() => closeReminder(reminder._id)}>&times;</span>
          </div>
        </div>
      </ReminderStyled>
    );
  }
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllStudyManager();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [currentPage]);


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user._id;
    getAllStudyManager(currentPage);
    getAllReminder();




  }, [currentPage]);

  const closeReminder = async (id) => {
    // socket.emit("toggleReminder", reminderId, true);

    // preventDefault();
    await API.turnOffRemainder(
      id,
    )
      .then((resp) => {
        // console.log(resp);
        if (resp.status == 200) {
          toast.success(resp.data.message);
          // console.log(resp.data.data);
          setReminders(reminders.filter(reminder => reminder._id !== id));
        }
      })
      .catch((e) => toast.error(e.response.data.message));


  };
  const getAllStudyManager = async () => {
    try {
      const response = await API.getAllStudyManager({
        // id,
        page: currentPage,


      });
      const responseData = response.data;

      if (responseData && responseData.success) {
        setAllManager(responseData.data.managers);

      } else {
        // toast.error("Failed to fetch companies");
      }
    } catch (error) {
      // toast.error("Error fetching submissons: " + error.message);
    }
  };

  return (
    <>
      <Sidebar>
        <StudyStyled>
          <div>
            {reminders?.map((reminder) => (
              <ReminderComponent
                key={reminder._id}
                reminder={reminder}
                onClose={closeReminder}
              />
            ))}
          </div>
          <div className="Business-Main-Section">
            <div className="Business-Container">
              <div className="Sidebar-Header-Content">
                <div className="Sidebar-Header-Section">
                  <div></div>
                  <div className="Bell-Main-Section">

                    <div className="Avatar-Main-Section">
                      <IoPersonSharp className="Manager-Avatar" />
                      <div className="Avatar-Text-Section">
                        <span className="Avatar-Title">
                          {userData.userName}

                        </span>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="Call-Center-Title">
                  <span className="call-center">Study Manager</span>
                  {/* <div className='Title-Section'>
                    <span className='Study-Text'>Study Manger</span>

                    <span className='Study-Title' > <MdKeyboardArrowRight className='Study-icon' />Dr. Tabinda</span>
                  </div> */}
                </div>
              </div>
              <div className="Main-Detail-Box">
                <div className='Main-Content'>
                  <span className="Contact-Title"></span>
                  <button className='Add-File-button' onClick={() => navigate("/add-study-manager")}> + Add New</button>
                </div>




                <div className="Card-Container">
                  {allmanager?.map((item, index) => (

                    <div className='Card-Main-Section'>


                      <div className='Card-Section' >
                        <div className='Container' key={index}>
                          <div className='Icon-Border'>  <IoPersonSharp className='Card-Icon' /></div>
                          <div className="Card-Box">
                            <div className="Card-Box-Side" onClick={() => navigate(`/manager-studies-for-admin/${item._id}`)}>
                              <span className='Card-Title'>{item.userName}</span>
                              <span className='Card-Title'>{item.email}</span>
                              <span className='Card-Title'>{item.role}</span>
                            </div>

                            <span
                              className='Card-Title-1'
                              onClick={() => copyToClipboard(`https://clixs.net/join-a-trail/${item.sn}`)}
                              style={{ cursor: 'pointer', textDecoration: 'underline', marginBottom: '38px' }}
                            >
                              Join a trial main
                            </span>
                          </div>
                        </div>

                      </div>


                    </div>

                  ))
                  }      </div>
                <div className="pagination-container">
                  <span className="pagination-text">

                  </span>
                  <div className="pagination-buttons">
                    <button
                      className="page-button"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {Array.from(
                      Array(allmanager?.pagination?.totalPages).keys()
                    ).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={`page-button${currentPage === pageNumber + 1 ? " active" : ""
                          }`}
                        onClick={() => setCurrentPage(pageNumber + 1)}
                      >
                        {pageNumber + 1}
                      </button>
                    ))}
                    <button
                      className="page-button"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={
                        currentPage === allmanager?.pagination?.totalPages
                      }
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StudyStyled>
      </Sidebar >
    </>
  )
}

export default StudyManager