import styled from "styled-components";

export const Partnerstyled = styled.div`
  background-color: #f3f3f9;
  width: 100%;
  display: flex;

  justify-content: center;

  .Parter-Main-Section {
    background-color: white;
    width: 99%;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
 
  .Table-Icons {
    width: 28px;
    height: 24px;
    background-color: #eaf5fb;
    border: 1px solid #3577f1;
    color: #3577f1;
    border-radius: 5px;
  }
  .Table-Icons1 {
    width: 28px;
    height: 24px;
    background-color: #fcefec;
    border: 1px solid #f06548;
    color: #f06548;
    border-radius: 5px;
  }
  .Icons-Gapping {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .Partner-Container {
    display: flex;
    height: 365px;
    flex-direction: column;
    width: 95%;
  }
  .Partner-Form-Header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-bottom: 1px solid #34437a;
  }
  .Header-Text {
    font-size: 20px;
    font-weight: 500;
  }
  .Header-Button-Section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .Header-icon {
    width: 28px;
    height: 24px;
    background-color: #ebecf2;
    border: 1px solid #34437a;
    color: #34437a;
    border-radius: 5px;
  }
  .Header-Button {
    width: 190px;
    height: 46px;
    gap: 10px;
    cursor: pointer;
    background-color: #34437a;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
  }
  .kyc-search1 {
    width: 230px;

    height: 15px;
    font-size: 14px;
    border: none;
    outline: none;
    color: black;
    background-color: white;
  }

  .kyc-search-box1 {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
 
    cursor: pointer;
  }
  .kyc-icon1 {
    width: 20px;
    height: 18px;
    color: #878a99;
  }
  .Name-Filter-Box {
    display: flex;
    align-items: center;
    width: 200px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .Table-Scrolling{
    width: 997px;
    white-space: nowrap;
    margin-top: 20px;
    overflow: auto;
  }
  .table-wrapper {
    margin: 20px;
    overflow-x: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 16px;
  }

  thead {
    background-color: #f4f4f4;
    font-size: 15px;
  }

  th,
  td {
    padding: 12px 12px;
    border: 1px solid #ddd;
  }

  tbody tr:nth-child(even) {
  }

  tbody tr:hover {
    background-color: #f1f1f1;
  }

  th {
   
    top: 0;
  }

  /* Style the pagination */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination button {
    padding: 5px 15px;
    margin: 0 5px;
    border: 1px solid #ddd;
    background: #f4f4f4;
    cursor: pointer;
  }

  .pagination button.active {
    background-color: #007bff;
    color: white;
  }

  .pagination button:hover {
    background-color: #007bff;
    color: white;
  }
  .Status-Color {
    color: #007bff;
  }
  .Status-Color1 {
    color: red;
  }
  .Client-Color {
    color: #007bff;
  }
  .pagination-container {
    display: flex;
    justify-content: space-between;
    width: 96%;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .pagination-text {
    font-size: 15px;
    color: #878a99;
  }
  .pagination-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .page-button {
    width: 88px;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    color: #878a99;
  }
  .page-button1 {
    width: 35px;
    height: 32px;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    background-color: #34437a;
    border: 1px solid #34437a;
  }
  .page-button2 {
    width: 35px;
    height: 32px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    background-color: white;
    border: 1px solid #ced4da;
  }
  .page-button3 {
    width: 58px;
    height: 32px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    background-color: white;
    border: 1px solid #ced4da;
  }
  .Header-Button2 {
    width: 64px;
    height: 40px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    background-color: #ebecf2;
    border: 1px solid #7882a7;
    color: #3f4e81;
  }
  .Sidebar-Header-Section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #f1f1f7;
    padding-bottom: 10px;
  }
  .Sidebar-Header-Content {
    width: 100%;
    display: flex;

    flex-direction: column;
  }
  .Sidebar-Search-Box-Section {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .kyc-search {
    width: 200px;
    height: 29px;
    font-size: 13px;
    border: none;
    outline: none;
    color: black;
    background-color: #edeaf4;
  }
  .kyc-search-box {
    display: flex;
    align-items: center;
    background-color: #edeaf4;
    padding: 4px;
    border-radius: 4px;
    margin-left: 20px;
    cursor: pointer;
  }
  .kyc-icon {
    width: 20px;
    height: 18px;
    color: #878a99;
  }

  .Listing-Icon {
    width: 47px;
    height: 30px;
    color: #878a99;
  }
  .Avatar-Main-Section {
    display: flex;
    background-color: #f3f3f9;
    width: 165px;
    height: 64px;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .Avatar-Title {
    font-size: 14px;
    font-weight: 400;

    color: #2d2f39;
  }
  .Avatar-Text {
    font-size: 15px;
    font-weight: 500;
    color: #2d2f39;
  }
  .Manager-Avatar {
    height: 35px;
    width: 24px;
  }
  .Avatar-Text-Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Bell-Main-Section {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 10px;
  }
  .Bell-Icon {
    color: #495057;
    width: 30px;
    height: 25px;
  }
  .Call-Center-Title {
    font-size: 20px;
    font-weight: 500;
    color: #2d2f39;
    background-color: #ffffff;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 16px;
  }
  .Business-Main-Section {
    width: 100%;
    display: flex;
    flex-direction: column;

    height: 100vh;
    overflow: auto;
  }
  .Business-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .page-button:hover {
    background-color: #71C495;
    color: white;
  }
  .page-button2:hover {
    background-color: #71C495;
    color: white;
  }
  .page-button3:hover {
    background-color: #71C495;
    color: white;
  }
  .call-center {
    margin-left: 30px;
  }
  .icon {
  font-size: 18px; /* Adjust icon size as needed */
  padding: 5px;
}

.success {
  color: blue;
}
.Table-Icons {
    width: 28px;
    height: 24px;
    background-color: #eaf5fb;
    border: 1px solid #3577f1;
    color: #3577f1;
    border-radius: 5px;
  }
  .Table-Icons1 {
    width: 28px;
    height: 24px;
    background-color: #fcefec;
    border: 1px solid #f06548;
    color: #f06548;
    border-radius: 5px;
  }
  .Icons-Gapping {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .Comment-Button{
    width: 144px;
    height:36px;
    background-color: #71C495;
    color: white;
    border-radius: 4px;
    border: none;

  }
  .Header-Details{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    white-space: nowrap;
  }
  .Header-Title{
    font-size: 13px;
  }
  @media (min-width: 1237px) {
    .Table-Scrolling{
    width: 904px;
    white-space: nowrap;

    overflow: auto;
  }
}
@media (min-width: 1366px) {
    .Table-Scrolling{
    width: 1015px;
    white-space: nowrap;
    overflow: auto;
  }
  }
@media (min-width: 1515px) {
    .Table-Scrolling{
    width: 1155px;
    white-space: nowrap;
    overflow: auto;
  }
  }

   @media (min-width: 1641px) {
    .Table-Scrolling{
    width: 1340px;
    white-space: nowrap;
    
    overflow: auto;
  }
  }
 @media (min-width: 1745px) {
    .Table-Scrolling{

      width: 1430px;
    white-space: nowrap;
    overflow: auto;
  }
  } 
  @media (min-width: 1845px) {
    .Table-Scrolling{

      width: 1640px;
    white-space: nowrap;
    overflow: auto;
  }
  } 

  @media (min-width: 2149px) {
    .Table-Scrolling{

      width: 2300px;
    white-space: nowrap;
    overflow: auto;
  }
  } 

`;
export const ModalStyled = styled.div`
 .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background-color: #F4F4F4;
    padding: 10px;
    width: 400px;

    height: 190px;
    border-radius: 12px;
    position: relative;

}
.Closing-Section{
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    width: 100%;

}
.Title {
    font-size: 18px;
    font-weight: 500;

  }
.close {
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
    font-size: 40px;
  }
`;
export const ModalFormstyled = styled.div`
display: flex;
width: 100%;
background-color: #F3F3F9;
height: 130px;

  .icon.success {
    color: green;
}

.icon.error {
    color: red;
}

 .icon.highlight {
    background-color: lightgreen;
    border-radius: 50%;
    height: 30px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
 
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .kyc-search {
    width: 200px;
    height: 29px;
    font-size: 13px;
    border: none;
    outline: none;
    color: black;
    background-color: #edeaf4;
  }
  .kyc-search-box {
    display: flex;
    align-items: center;
    background-color: #edeaf4;
    padding: 4px;
    border-radius: 4px;
    margin-left: 20px;
    cursor: pointer;
  }
  .kyc-icon {
    width: 20px;
    height: 18px;
    color: #878a99;
  }

  .Listing-Icon {
    width: 47px;
    height: 30px;
    color: #878a99;
  }
  
 

   .Call-Center-Title {
 
 
    color: #2d2f39;
    background-color: #ffffff;
    padding-left: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}
.call-center{
    font-size: 20px;
    font-weight: 500;
}
.Title-Section{
    display: flex;
    align-items: center;

}
.Study-Text{
    font-size: 16px;

}
.Study-icon{
    height: 20px;
    width: 22px;
}
.Study-Title{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
   color: #878A99;
   margin-right: 10px;
}
  .Main-Detail-Box-1{
    display: flex;
    /* justify-content: center; */
    background-color:#F4F4F4;

    width: 100%;
    flex-direction: column;

    border-radius: 5px;
  }
.Names-Form-1{
    display: flex;
    flex-direction: column;
    align-items: center;
   
    width: 100%;
    justify-content: center;
}
.Form-Box-Text-Bottom{
    display: flex;
    flex-direction: column;
  
    
   
}
  .NameBox {
    width: 370px;
    height: 28px;
    font-size: 17px;
    border: none;
    background-color: white;
    padding-left: 10px;
    outline: none;
    color: black;
  }
  .FormBox {
    border: 2px #e6ebf2 solid;
    padding: 4px;
    border-radius: 9px;
    cursor: pointer;
    width: 380px;
    display: flex;
    background-color: white;
    align-items: center;
  }
  .search-icon{
    width: 20px;
    height: 20px;
  }
.Card-Title{
    font-size: 16px;
    color: #878A99;
}
.pagination{
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 30px;
  gap: 10px;
  width: 97%;
}
.pagination-buttons-modal{
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    gap: 10px;
}
  .Button-2 {
    width: 88px;
    cursor: pointer;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #71C495;
    font-size: 15px;
    font-weight: 500;
    color: white;
  }
  .Button-1{
    width: 88px;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    font-size: 15px;
    font-weight: 500;
   color: black;
  }
  .Button-1:hover{
background-color: #71C495;
color: white
;
  }
  .Button-2:hover{
background-color: #71C495;
}

.status-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px; /* Adjust width as needed */
}

.icon {
  font-size: 24px; /* Adjust icon size as needed */
  padding: 5px;
  cursor: pointer;
}

.success {
  color: blue;
  cursor: pointer;
}

.pending {
  color: grey;
}

.error {
  color: red;
  cursor: pointer;
}
.Icons-Section{
  
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    
    width: 100%;
    padding-top: 10px;
  

}
`;
export const ReminderStyled = styled.div`

.reminder{
  width: 300px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
position: absolute;
 backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    background-color: hsla(228, 24%, 6%, .2);
    right: 50px;
top: 10px;


border-radius: 12px;
}
.reminder-content {
    display: flex;
    justify-content: space-between;
    width: 90%;
    flex-direction: column;
    gap: 8px;
}
.close {
  position: absolute;
  top: -9px;
  right: 10px;
  cursor: pointer;
  font-size: 30px;
}
.Title-Section{
  display: flex;
  align-items: center;
gap: 5px;
}
.Reminder-Title{
font-weight: 500;
  font-size: 17px;
}
.Icon{
  width: 22px; 
  height: 20px;
}
`;