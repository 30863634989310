import React, { useState } from 'react';
import { API } from "../../../../api/api";
import { toast } from "react-toastify";
import Sidebar from "../../../Sidebar/SideBar"
import { StudyStyled } from "./style"
import { IoPersonSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";


function AddStudy() {
  const navigate = useNavigate();
  const [studyName, setStudyName] = useState("");
  const [studyID, setStudyID] = useState("");
  const [dateOfReceipt, setDateOfReceipt] = useState("");
  console.log(dateOfReceipt)
  const [sponserName, setSponserName] = useState("");
  const [cro, setCro] = useState("");
  const [email, setEmail] = useState("");
  const [trailManager, setTrailManager] = useState("");
  const [otherName, setOtherName] = useState("");
  const [otherEmail, setOtherEmail] = useState("");

  const userData = JSON.parse(localStorage.getItem("user"));
  const oncreateStudyData = async (e) => {
    e.preventDefault();

    if (!studyName) {
      return toast.error("Please Enter  Study Name");
    }
    if (!studyID) {
      return toast.error("Please Enter Study ID");
    }
    if (!dateOfReceipt) {
      return toast.error("Please Enter Of Receipt Date");
    }
    if (!email) {
      return toast.error("Please Enter Email");
    }
    if (!sponserName) {
      return toast.error("Please Enter Sponser Name");
    }
    if (!cro) {
      return toast.error("Please Enter Cro");
    }
    if (!trailManager) {
      return toast.error("Please Enter Trail Manager");
    }
    if (!otherName) {
      return toast.error("Please Enter Other Name");
    } if (!otherEmail) {
      return toast.error("Please Enter Other Eame");
    }
    const createStudyData = {
      studyName,
      studyID,
      dateOfReceipt,
      sponserName,
      cro,
      email,
      trailManager,
      otherName,
      otherEmail,
    };

    await API.createStudy(createStudyData)
      .then((resp) => {
        if (resp.status == 200) {
          toast.success(resp.data.message);

          navigate("/study-list");
        }
      })
      .catch((e) => toast.error(e.response.data.message));
  };

  return (

    <>
      <Sidebar>
        <StudyStyled>
          <div className="Business-Main-Section">
            <div className="Business-Container">
              <div className="Sidebar-Header-Content">
                <div className="Sidebar-Header-Section">
                  <div></div>
                  <div className="Bell-Main-Section">

                    <div className="Avatar-Main-Section">
                      <IoPersonSharp className="Manager-Avatar" />
                      <div className="Avatar-Text-Section">
                        <span className="Avatar-Title">
                          {userData.userName}

                        </span>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="Call-Center-Title">
                  <span className="call-center">Add Study</span>

                </div>
              </div>
              <div className="Main-Detail-Box">
                <div className="Names-Form">
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">
                      Study Name</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="Name"
                        placeholder="Enter Study Name"
                        value={studyName}
                        onChange={(e) => setStudyName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">

                      Study ID</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="Name"
                        placeholder="Enter Study ID"
                        value={studyID}

                        onChange={(e) => setStudyID(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">

                      Date Of Receipt</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="date"
                        placeholder="Enter Activation Date"
                        value={dateOfReceipt}

                        onChange={(e) => setDateOfReceipt(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="Names-Form">
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">
                      Sponsor Name</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="Name"
                        placeholder="Enter Study Name"
                        value={sponserName}

                        onChange={(e) => setSponserName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">

                      CRO</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="Name"
                        placeholder="Enter Cro"
                        value={cro}

                        onChange={(e) => setCro(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">
                      Email Address</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="email"
                        placeholder="Enter Email"
                        value={email}

                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="Names-Form">

                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">

                      Trial Manager</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="Name"
                        placeholder="Enter Trial Manager"
                        value={trailManager}

                        onChange={(e) => setTrailManager(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">

                      Other Name</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="name"
                        placeholder="Enter Other Name"
                        value={otherName}

                        onChange={(e) => setOtherName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="Form-Box-Text-Bottom">
                    <span className="Form-Box-Title">
                      Other Email</span>
                    <div className="FormBox">
                      <input
                        className="NameBox"
                        type="email"
                        placeholder="Enter Other Email"
                        value={otherEmail}
                        onChange={(e) => setOtherEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="pagination">
                  <button className='Button-1' onClick={() => navigate("/study-list")}>Cancel</button>
                  <button className="Button-2" onClick={(e) => oncreateStudyData(e)}>Submit</button>

                </div>
              </div>
            </div>
          </div>
        </StudyStyled>
      </Sidebar>
    </>
  )
}

export default AddStudy