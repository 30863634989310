import React, { useState, useEffect } from 'react';
import Sidebar from "../../../Sidebar/SideBar"
import { useParams } from 'react-router-dom';
import { StudyStyled } from "./style"
import Logo from "../../../../images/login-logo.png"
import { useNavigate } from "react-router-dom";
import { API } from "../../../../api/api";
import { toast } from "react-toastify";

function AddSiteSubmisson() {
    const navigate = useNavigate();
    const [studyName, setStudyName] = useState("");
    const [siteName, setSiteName] = useState("");
    const [address, setAddress] = useState("");

    const [piName, setPiName] = useState("");
    const [specialty, setSpecialty] = useState("");


    const [file, setFile] = useState(null);

    const [submission, setSubmisson] = useState({});

    let { id } = useParams();


    const handleSiteNameChange = (e) => {
        const updateSubmisson = { ...submission, siteName: e.target.value };
        setSubmisson(updateSubmisson);
    };
    const handleAdressChange = (e) => {
        const updateSubmisson = { ...submission, address: e.target.value };
        setSubmisson(updateSubmisson);
    };

    const handlePiNameChange = (e) => {
        const updateSubmisson = { ...submission, piName: e.target.value };
        setSubmisson(updateSubmisson);
    };

    const handleSpecialtyChange = (e) => {
        const updateSubmisson = { ...submission, specialty: e.target.value };
        setSubmisson(updateSubmisson);
    };







    const handleSnpaChange = (e, boolean) => {
        if (boolean == true) {
            const updateSubmisson = { ...submission, snpa: true };
            setSubmisson(updateSubmisson);
        } else {
            const updateSubmisson = { ...submission, snpa: false };
            setSubmisson(updateSubmisson);
        }


    };



    useEffect(() => {
        getSubmisson(id);
    }, []);
    const getSubmisson = async (id) => {

        await API.getSubmisson(id,
        )
            .then((resp) => {
                console.log();
                if (resp.status == 200) {
                    setSubmisson(resp.data.data);
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };



    console.log(submission)
    const updateSubmisson = async () => {
        submission.id = submission._id;
        const formData = new FormData()
        formData.append('id', submission._id);
        formData.append('siteName', submission.siteName);
        formData.append('piName', submission.piName);
        formData.append('specialty', submission.specialty);
        formData.append('snpa', submission.snpa);


        console.log(file)

        if (file) {
            formData.append('file', file);
        }
        await API.updateSubmisson(formData)

            .then((resp) => {
                // console.log();
                if (resp.status == 200) {
                    // console.log(resp.data.data);
                    // setLead(resp.data.data);
                    getSubmisson(id);
                    navigate("/snpa");
                    toast.success(resp.data.message);
                }
            })
            .catch((e) => toast.error(e.response.data.message));
    };

    return (

        <>
            <Sidebar>
                <StudyStyled>
                    <div className="Business-Main-Section">
                        <div className="Business-Container">
                            <div className="Sidebar-Header-Content">
                                <div className="Sidebar-Header-Section">
                                    <div></div>
                                    <div className="Bell-Main-Section">
                                    </div>
                                </div>

                                <div className="Call-Center-Title">
                                    <span className="call-center">SNPA Repository</span>

                                </div>
                            </div>
                            <div className="Main-Detail-Box">

                                <div className="Names-Form">

                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Site Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Site Name"
                                                value={
                                                    submission.siteName ? submission.siteName : siteName
                                                }
                                                onChange={handleSiteNameChange}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="Names-Form">
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">

                                            Address</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Address"


                                                value={
                                                    submission.address ? submission.address : address
                                                }
                                                onChange={handleAdressChange}

                                            />
                                        </div>
                                    </div>
                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            PI Name</span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter PI Name"

                                                value={
                                                    submission.piName ? submission.piName : piName
                                                }
                                                onChange={handlePiNameChange}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="Names-Form">

                                    <div className="Form-Box-Text-Bottom">
                                        <span className="Form-Box-Title">
                                            PI Specialty </span>
                                        <div className="FormBox">
                                            <input
                                                className="NameBox"
                                                type="Name"
                                                placeholder="Enter Specialty"

                                                value={
                                                    submission.specialty ? submission.specialty : specialty
                                                }
                                                onChange={handleSpecialtyChange}

                                            />
                                        </div>
                                    </div>

                                </div>







                                <div className="Names-Form">
                                    <div className='Icons-Section'>

                                        <div className="status-icons">
                                            <span className='Title'>SNPA</span>
                                            <span
                                                className={`icon ${submission.snpa == true ? 'highlight' : ''} success`}
                                                onClick={(e) => handleSnpaChange(e, true)}
                                            >
                                                ✔
                                            </span>
                                            <span
                                                className={`icon ${submission.snpa == false ? 'highlight' : ''} error`}
                                                onClick={(e) => handleSnpaChange(e, false)}
                                            >
                                                ✖
                                            </span>
                                        </div>

                                    </div>


                                </div>



                                <div className="pagination">
                                    <button className='Button-1' onClick={() => navigate("/snpa")}>Cancel</button>
                                    <button className="Button-2" onClick={(e) => updateSubmisson(e)}>Submit</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </StudyStyled >
            </Sidebar>
        </>
    )
}


export default AddSiteSubmisson